/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useRef } from 'react';

// From react docs
// https://legacy.reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
export default function useEventCallback<T extends (...args: any[]) => any>(
    callback: T,
    deps: React.DependencyList,
): T {
    const ref = useRef<T>(callback);

    useEffect(() => {
        ref.current = callback;
    }, [callback, ...deps]);

    return useCallback(
        (...args: Parameters<T>): ReturnType<T> => {
            const fn = ref.current;
            return fn(...args);
        },
        [ref],
    ) as T;
}
