export const getTextElements = (text: string, citations: Citation[]) => {
    const citationsMarkdown = citations.map(
        citation =>
            `<button data='${JSON.stringify(citation).replace(
                /'/g,
                '&#39;',
            )}'></button>`,
    );

    const endOfTextPunctuation = text.match(/[.!?]?\n?$/) ?? [''];
    const matchedLength = endOfTextPunctuation[0].length;
    const mainText = matchedLength === 0 ? text : text.slice(0, -matchedLength);
    const endOfText = endOfTextPunctuation[0];

    return { citationsMarkdown, mainText, endOfText };
};

export const entryToTextMessage = (entry: Entry) => {
    if (entry.user_action && entry.user_action.action_type === 'utterance') {
        return {
            type: 'user',
            actionId: entry.user_action.id,
            text: entry.user_action.payload.text,
        };
    } else if (
        entry.agent_action &&
        entry.agent_action.action_type === 'utterance'
    ) {
        if (entry.agent_action.payload.textual) {
            let displayText: AvatarText = [];
            const { chunks } = entry.agent_action.payload;
            if (chunks && chunks.length) {
                chunks.forEach(({ text, citations }) => {
                    const {
                        mainText,
                        citationsMarkdown,
                        endOfText,
                    } = getTextElements(text, citations);

                    displayText.push(
                        ...mainText.split(''),
                        ...citationsMarkdown,
                        endOfText,
                    );
                });
            } else {
                displayText = entry.agent_action.payload.textual.text.split('');
            }
            return {
                type: 'agent',
                actionId: entry.agent_action.id,
                text: displayText,
                attachments: entry.agent_action.payload.media?.attachments,
                className: entry.agent_action.payload.textual.hidden
                    ? 'font-italic'
                    : undefined,
            } as AgentTextMessage;
        }
    }
};

export const parseChatEntries = (entries: Entry[]): TextMessage[] => {
    const sortedInteractionMessages = entries.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA.getTime() - dateB.getTime();
    });

    return sortedInteractionMessages
        .map(entryToTextMessage)
        .filter(m => m !== undefined) as TextMessage[];
};

export const entryToTextMessageOld = (entry: Entry) => {
    if (entry.user_action && entry.user_action.action_type === 'utterance') {
        return {
            type: 'user',
            actionId: entry.user_action.id,
            text: entry.user_action.payload.text,
        };
    } else if (
        entry.agent_action &&
        entry.agent_action.action_type === 'utterance'
    ) {
        if (entry.agent_action.payload.textual) {
            return {
                type: 'agent',
                actionId: entry.agent_action.id,
                text: entry.agent_action.payload.textual.text,
                attachments: entry.agent_action.payload.media?.attachments,
                className: entry.agent_action.payload.textual.hidden
                    ? 'font-italic'
                    : undefined,
            };
        }
    }
};
export const parseChatEntriesOld = (entries: Entry[]): TextMessage[] => {
    const sortedInteractionMessages = entries.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA.getTime() - dateB.getTime();
    });

    return sortedInteractionMessages
        .map(entryToTextMessageOld)
        .filter(m => m !== undefined) as TextMessage[];
};

export const getLastAgentMessage = (messages: TextMessage[]) => {
    const agentMessages = messages.filter(message => message.type === 'agent');

    if (agentMessages.length > 0) {
        return agentMessages[agentMessages.length - 1];
    }
};
