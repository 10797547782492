import * as React from 'react';
import { Fragment, HTMLProps, useState } from 'react';
import { Box, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import CitationButton from '../CitationButton/CitationButton';
import { getChatStyles } from '../../theme/consts';
import FeedbackButton from '../FeedbackButton/FeedbackButton';
import AgentActionFeedbackModal from '../../molecules/AgentActionFeedbackModal/AgentActionFeedbackModal';
import useFeedbackSubmit from '../../hooks/useFeedbackSubmit';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface Props {
    actionId: number;
    showFeedbackButtons: boolean;
    text: AvatarText;
    onCitationClick: (citation: Citation) => void;
    isTextMode: boolean;
}

const AvatarMessage: React.FC<Props> = ({
    actionId,
    showFeedbackButtons,
    text,
    onCitationClick,
    isTextMode,
}) => {
    const [submitted, setSubmitted] = useState<FeedbackPayload['type'] | null>(
        null,
    );
    const [messageHover, setMessageHover] = useState(false);

    const styles = getChatStyles(isTextMode);

    const {
        handleFeedbackSubmit,
        isModalOpen,
        setIsModalOpen,
    } = useFeedbackSubmit(actionId, setSubmitted);

    return (
        <Fragment>
            <AgentActionFeedbackModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleFeedbackSubmit}
            />
            <Stack
                spacing={0}
                onMouseEnter={() => setMessageHover(true)}
                onMouseLeave={() => setMessageHover(false)}
            >
                <Box
                    className='fs-mask'
                    borderRadius='10px'
                    m='0'
                    color='white'
                    wordBreak='break-word'
                    {...styles.avatarMessage}
                >
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            h1: ({ children }) => (
                                <Heading as='h1' size='md' fontFamily='inherit'>
                                    {children}
                                </Heading>
                            ),
                            h2: ({ children }) => (
                                <Heading as='h2' size='sm' fontFamily='inherit'>
                                    {children}
                                </Heading>
                            ),
                            p: ({ children }) => <Text m={0}>{children}</Text>,
                            button: (
                                props: HTMLProps<HTMLButtonElement> & {
                                    data?: string;
                                },
                            ) => {
                                const data = props.data
                                    ? JSON.parse(props.data)
                                    : undefined;

                                return (
                                    <CitationButton
                                        citationId={data.id}
                                        onClick={() => {
                                            onCitationClick(data);
                                        }}
                                    />
                                );
                            },
                        }}
                    >
                        {text.join('')}
                    </ReactMarkdown>
                </Box>
                <HStack h='40px' m='0px' justifyContent='flex-start'>
                    {(showFeedbackButtons || submitted || messageHover) && (
                        <Fragment>
                            <FeedbackButton
                                type='negative'
                                isSubmitted={submitted}
                                onSubmit={() => setIsModalOpen(true)}
                            />
                            <FeedbackButton
                                type='positive'
                                isSubmitted={submitted}
                                onSubmit={() =>
                                    handleFeedbackSubmit('positive')
                                }
                            />
                        </Fragment>
                    )}
                </HStack>
            </Stack>
        </Fragment>
    );
};

export default AvatarMessage;
