import React from 'react';
import useAsyncError from '../../../hooks/useAsyncError';
import { useQuery } from '@tanstack/react-query';
import { authService, keycloak, moocAPI } from '../../../services';
import {
    Avatar,
    Box,
    Button,
    Flex,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/react';
import ModalOpener from '../../generic/ModalOpener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import UserSettingsModal from '../../modals/UserSettingsModal';

const UserActionsAvatar: React.FC = () => {
    const throwAsyncError = useAsyncError();
    const { data: userData, isSuccess: userDataLoaded } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
        {
            onError: throwAsyncError,
        },
    );

    return (
        <Popover placement='right-end'>
            <PopoverTrigger>
                <Avatar
                    size='md'
                    name={
                        userDataLoaded
                            ? `${userData.first_name} ${userData.last_name}`
                            : undefined
                    }
                    className='fs-mask'
                    color='white'
                    bg='brand.gradient.full'
                    cursor='pointer'
                />
            </PopoverTrigger>
            {userDataLoaded && (
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>
                        <Flex gap={4}>
                            <Avatar
                                size='md'
                                name={`${userData.first_name} ${userData.last_name}`}
                                color='white'
                                bg='brand.gradient.full'
                            />
                            <Flex
                                direction='column'
                                wordBreak='break-all'
                                className='fs-exclude'
                            >
                                <HStack spacing={1}>
                                    <Box>{userData.first_name}</Box>
                                    <Box>{userData.last_name}</Box>
                                </HStack>
                                {userData.email}
                            </Flex>
                        </Flex>
                    </PopoverHeader>
                    <PopoverBody p={0}>
                        <Flex direction='column'>
                            {!(keycloak?.tokenParsed as any)?.external_idp && (
                                <ModalOpener Modal={UserSettingsModal}>
                                    <Button
                                        variant='ghost'
                                        justifyContent='start'
                                        px={8}
                                        py={6}
                                        borderRadius={0}
                                        fontWeight='normal'
                                        leftIcon={
                                            <FontAwesomeIcon icon={faCog} />
                                        }
                                    >
                                        Settings
                                    </Button>
                                </ModalOpener>
                            )}
                            <Button
                                variant='ghost'
                                justifyContent='start'
                                px={8}
                                py={6}
                                borderRadius={0}
                                fontWeight='normal'
                                leftIcon={
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                }
                                onClick={() => authService.logout()}
                            >
                                Log out
                            </Button>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            )}
        </Popover>
    );
};

export default UserActionsAvatar;
