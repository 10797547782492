const ROUTES = {
    HOME: '/',
    LOGIN: 'login',
    REGISTRATION: 'register',
    USER_SETTINGS: 'settings',
    LOGOUT: 'logout',

    PURCHASE: 'purchase',
    PAYMENT: 'payment',
    PAYMENT_SUCCESS: 'payment-success',
    PAYMENT_PROCESSING: 'process-payment',

    COURSES: 'courses',
    MY_PROGRESS: 'progress',
    COURSES_COMPLETED: 'courses/completed',
    COURSES_ALL: 'courses/all',
    DEPRECATED_COURSE_OVERVIEW: 'course',
    ACTIVITY: 'activity',

    PORTFOLIO: 'portfolio',
    GROUP_OVERVIEW: 'portfolio/group',
    UPLOAD_CASE: 'portfolio/upload-case',
    CASE_OVERVIEW: 'portfolio/case',

    INSIGHTS: 'insights', // Replaced with certifications
    CERTIFICATIONS: 'certifications',
    CERTIFICATE: 'achievements/certificates',

    LEADERBOARD: 'leaderboard',

    PAGE_NOT_FOUND: 'page-not-found',
};

export default ROUTES;
