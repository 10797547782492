import React, { Fragment, useContext } from 'react';
import StudentFeaturesContext from '../../components/contexts/StudentFeaturesContext';
import axios from 'axios';
import {
    Box,
    Flex,
    Heading,
    Text,
    Button,
    Stack,
    Center,
} from '@chakra-ui/react';
import DashboardPageLayout from '../../components/layouts/DashboardPageLayout';
import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { moocAPI } from '../../services';
import { courseCompare } from '../CoursesPage';
import Section, {
    CarouselSectionCourses,
    CarouselSectionActivities,
    CollapsibleSection,
    COURSE_CARD_SPACING,
} from './Section';
import CourseCard from '../../components/courses/CourseCardV2';
import config from '../../consts/config';
import { useActivities } from '../CourseOverviewPageV2/useCourse';
import UserInfo from './UserInfo';
import { Link as RouterLink } from 'react-router-dom';
import ROUTES from '../../consts/routes';

const getTrialCourses = async () => {
    const resp = await axios.get<Course[]>(
        config.REACT_APP_TRIAL_LOCKED_COURSES_URL!,
        {
            headers: { Accept: 'application/json' },
        },
    );
    return resp.data;
};

const useTrialLockedCourses = () => {
    const { trialData } = useContext(StudentFeaturesContext);
    const isEnabled =
        false && // Disable locked courses disaplay
        trialData.is_trial &&
        !!config.REACT_APP_TRIAL_LOCKED_COURSES_URL;
    const { data } = useQuery(['trial-locked-courses'], getTrialCourses, {
        enabled: isEnabled,
    });

    return { data, isEnabled };
};

export const useCoursesQueries = (
    status: ('available' | 'in-progress' | 'completed')[],
): UseQueryResult<Course[]>[] => {
    return useQueries({
        queries: status.map(status => {
            return {
                queryKey: ['courses', status],
                queryFn: async () =>
                    ((await moocAPI.get(
                        `courses/?status=${status}`,
                    )) as Course[]).sort((c1, c2) =>
                        courseCompare(c1, c2, status),
                    ),
            };
        }),
    });
};

const CoursesPageV2: React.FC = () => {
    const [availableCoursesQuery, inProgressCoursesQuery] = useCoursesQueries([
        'available',
        'in-progress',
    ]);

    const {
        data: trialLockedCourses,
        isEnabled: shouldShowLockedCourses,
    } = useTrialLockedCourses();
    const showCourses =
        !!availableCoursesQuery.data?.length ||
        availableCoursesQuery.isLoading ||
        !!inProgressCoursesQuery.data?.length ||
        inProgressCoursesQuery.isLoading ||
        shouldShowLockedCourses;

    const {
        activities,
        isLoading: activitiesLoading,
        isSuccess: activitiesLoaded,
    } = useActivities();

    return (
        <DashboardPageLayout noPadding>
            <UserInfo containerProps={{ mt: 12, mx: 10 }} />
            <Box paddingY={5} paddingX={10}>
                <Stack spacing={10}>
                    {!activities?.length && !showCourses ? (
                        <Center bg='white' p={10} borderRadius='15px'>
                            <Stack alignItems='flex-start' gap={3}>
                                <Heading size='lg'>
                                    You can find available courses and
                                    activities here
                                </Heading>
                                <Text mt='0px!important'>
                                    Currently there is nothing available. Please
                                    visit <b>My progress</b> to check your
                                    progress.
                                </Text>
                                <Button
                                    as={RouterLink}
                                    to={`/${ROUTES.MY_PROGRESS}`}
                                    colorScheme='brand.black'
                                    _hover={{
                                        color: 'white',
                                    }}
                                >
                                    My Progress
                                </Button>
                            </Stack>
                        </Center>
                    ) : (
                        <Fragment>
                            {(activitiesLoading || !!activities?.length) && (
                                <CarouselSectionActivities
                                    heading={'Activities'}
                                    isLoaded={activitiesLoaded}
                                    activities={activities}
                                />
                            )}

                            {(inProgressCoursesQuery.isLoading ||
                                !!inProgressCoursesQuery.data?.length) && (
                                <CarouselSectionCourses
                                    heading={'Continue'}
                                    subHeading={'Jump back in'}
                                    isLoaded={inProgressCoursesQuery.isSuccess}
                                    courses={inProgressCoursesQuery.data}
                                    courseCardVariant='in-progress'
                                />
                            )}

                            {(availableCoursesQuery.isLoading ||
                                !!availableCoursesQuery.data?.length) && (
                                <CollapsibleSection
                                    forceOpen={
                                        inProgressCoursesQuery.isSuccess &&
                                        !inProgressCoursesQuery.data?.length
                                    }
                                    heading='Try something new'
                                    subHeading='More courses for you'
                                    courseCardVariant='un-enrolled'
                                    isLoaded={availableCoursesQuery.isSuccess}
                                    courses={availableCoursesQuery.data}
                                />
                            )}
                        </Fragment>
                    )}

                    {!!trialLockedCourses?.length && (
                        <Section
                            heading={
                                <Box mt={4}>
                                    Please upgrade for full catalog
                                </Box>
                            }
                            isLoaded
                            data={trialLockedCourses}
                            noSkeletonItems
                        >
                            <Flex gap={COURSE_CARD_SPACING} wrap='wrap'>
                                {trialLockedCourses?.map((course, i) => (
                                    <CourseCard
                                        key={i}
                                        variant='locked'
                                        course={course}
                                    />
                                ))}
                            </Flex>
                        </Section>
                    )}
                </Stack>
            </Box>
        </DashboardPageLayout>
    );
};

export default CoursesPageV2;
