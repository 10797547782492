import {
    faAward,
    faUsers,
    faHome,
    IconDefinition,
    faTrophy,
    faTasks,
} from '@fortawesome/free-solid-svg-icons';
import ROUTES from '../../../consts/routes';
import { MoocFeature } from '../../contexts/StudentFeaturesContext';

const NavItems: {
    icon: IconDefinition;
    text: string;
    feature: MoocFeature;
    route: string;
}[] = [
    {
        icon: faHome,
        text: 'Home',
        feature: 'courses',
        route: `/${ROUTES.COURSES}`,
    },
    {
        icon: faTasks,
        text: 'My progress',
        feature: 'courses',
        route: `/${ROUTES.MY_PROGRESS}`,
    },
    {
        icon: faUsers,
        text: 'Teams',
        route: `/${ROUTES.PORTFOLIO}`,
        feature: 'portfolio',
    },
    {
        icon: faAward,
        text: 'Certifications',
        route: `/${ROUTES.CERTIFICATIONS}`,
        feature: 'courses',
    },
    {
        icon: faTrophy,
        text: 'Leaderboard',
        route: `/${ROUTES.LEADERBOARD}`,
        feature: 'leaderboard',
    },
];

export default NavItems;
