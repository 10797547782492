import * as React from 'react';
import {
    Avatar,
    Box,
    Button,
    Flex,
    HStack,
    Image,
    SimpleGrid,
    Stack,
} from '@chakra-ui/react';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import UserMessage from '../../atoms/UserMessage/UserMessage';
import { getChatStyles } from '../../theme/consts';
import { useStore } from '../../stores';
import useStateWithDelay from '../../hooks/useStateWithDelay';

interface Props {
    avatarImageSrc: string;
    isTextMode: boolean;
    onCitationClick: (citation: Citation) => void;
    messages: TextMessage[];
    innerContainerRef: React.RefObject<HTMLDivElement>;
}

type MessageProps = Omit<Props, 'messages' | 'innerContainerRef'> & {
    message: TextMessage;
    showFeedbackButtons: boolean;
};

interface AttachmentProps {
    url: string;
    name?: string;
    w?: string;
    h?: string;
    onClick?: () => void;
}
const Attachment: React.FC<AttachmentProps> = ({
    name,
    url,
    w,
    h,
    onClick,
}) => (
    <Button
        variant='unstyled'
        p='0px'
        w={w}
        h={h || 'max-content'}
        onClick={onClick}
    >
        <Image
            src={url}
            alt={name}
            objectFit='cover'
            w={w}
            h={h}
            borderRadius='10px'
        />
    </Button>
);

const _Message: React.FC<MessageProps> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    message,
    showFeedbackButtons,
}) => {
    const setAttachment = useStore(state => state.setAttachment);
    const setCurrentModal = useStore(state => state.setCurrentModal);

    if (message.type === 'user') {
        return (
            <HStack justifyContent='flex-end'>
                <UserMessage text={message.text} isTextMode={isTextMode} />
            </HStack>
        );
    } else {
        const hasAttachments =
            message.attachments && message.attachments?.length > 0;
        return (
            <HStack alignItems='flex-start'>
                {isTextMode && <Avatar src={avatarImageSrc} boxSize='32px' />}
                <Box>
                    <AvatarMessage
                        actionId={message.actionId}
                        showFeedbackButtons={showFeedbackButtons}
                        text={message.text}
                        onCitationClick={onCitationClick}
                        isTextMode={isTextMode}
                    />
                    {hasAttachments && (
                        <Flex wrap={'wrap'} gap={4} mt={3}>
                            {message.attachments!.map((attachment, i) => (
                                <Attachment
                                    key={i}
                                    name={attachment.name}
                                    url={attachment.url}
                                    w='125px'
                                    h='125px'
                                    onClick={() => {
                                        setCurrentModal('attachment');
                                        setAttachment({
                                            title: attachment.name,
                                            description: attachment.description,
                                            url: attachment.url,
                                        });
                                    }}
                                />
                            ))}
                        </Flex>
                    )}
                </Box>
            </HStack>
        );
    }
};

// The message list changes often (especially with the typewriter effect running)
// so we use memoized components to skip re-rendering the already existing messages
const Message = React.memo(_Message, (prevProps, nextProps) => {
    return (
        prevProps.message.actionId === nextProps.message.actionId &&
        // Length eq is enough under the assumption that appending is the only operation on message.text
        prevProps.message.text.length === nextProps.message.text.length &&
        prevProps.isTextMode === nextProps.isTextMode &&
        prevProps.onCitationClick === nextProps.onCitationClick &&
        prevProps.avatarImageSrc === nextProps.avatarImageSrc &&
        prevProps.showFeedbackButtons === nextProps.showFeedbackButtons
    );
});

const ChatHistory: React.FC<Props> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    messages,
    innerContainerRef,
}) => {
    const styles = getChatStyles(isTextMode);

    return (
        <Stack ref={innerContainerRef} {...styles.historyContainer}>
            {messages.map((message, index) => (
                <Message
                    key={index}
                    message={message}
                    isTextMode={isTextMode}
                    onCitationClick={onCitationClick}
                    avatarImageSrc={avatarImageSrc}
                    showFeedbackButtons={index === messages.length - 1}
                />
            ))}
        </Stack>
    );
};

export const AttachmentsDisplay: React.FC<{
    messages: TextMessage[];
    isTextMode: boolean;
}> = ({ messages, isTextMode }) => {
    // Global state
    const setAttachment = useStore(state => state.setAttachment);
    const setCurrentModal = useStore(state => state.setCurrentModal);

    // Attachments should be displayed after the avatar finishes animating
    const lastMessage = messages[messages.length - 1];
    const _attachments =
        lastMessage.type === 'agent' ? lastMessage.attachments : undefined;

    const [
        attachments,
        setAttachments,
        setAttachmentsWithDelay,
    ] = useStateWithDelay<MediaAttachment[] | undefined>(_attachments);

    React.useEffect(() => {
        if (_attachments?.length) {
            setAttachmentsWithDelay(_attachments, 4000); // Approx 4s to complete the animation
        } else {
            setAttachments(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage.actionId, _attachments?.length]);

    const styles = getChatStyles(isTextMode);

    if (attachments === undefined || attachments.length === 0) return null;

    return (
        <SimpleGrid
            spacingX='20px'
            spacingY='20px'
            my='auto'
            columns={Math.min(2, attachments.length)}
            {...styles.historyContainer}
        >
            {attachments.map(a => (
                <Attachment
                    key={a.name || a.url}
                    name={a.name}
                    url={a.url}
                    onClick={() => {
                        setCurrentModal('attachment');
                        setAttachment({
                            title: a.name,
                            description: a.description,
                            url: a.url,
                        });
                    }}
                />
            ))}
        </SimpleGrid>
    );
};

export default ChatHistory;
