import React from 'react';
import DashboardPageLayout from '../../components/layouts/DashboardPageLayout';
import { Flex, Heading, Spacer } from '@chakra-ui/react';
import LeaderboardView from './LeaderBoardView';

export default function Leaderboard() {
    return (
        <DashboardPageLayout noPadding>
            <Flex
                direction='column'
                bg={'brand.gradient.cold.full'}
                textColor='white'
                w='100%'
                h='135px'
            >
                <Spacer />
                <Flex px={10} py={4}>
                    <Heading fontWeight='normal'>Leaderboard</Heading>
                </Flex>
            </Flex>
            <LeaderboardView />
        </DashboardPageLayout>
    );
}
