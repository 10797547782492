import React, { useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Box,
    BoxProps,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Center,
    chakra,
    Flex,
    HStack,
    IconButton,
    Image,
    Link,
    Spacer,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import Logo from '../../../images/logo_ai_white_full.svg';
import LogoShort from '../../../images/logo_white_short.svg';
import {
    faChevronLeft,
    faChevronRight,
    faLightbulb,
} from '@fortawesome/free-solid-svg-icons';
import ROUTES from '../../../consts/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalOpener from '../../generic/ModalOpener';
import UserFeedbackModal from '../../modals/UserFeedbackModal';
import { isValidMotionProp, motion } from 'framer-motion';
import StudentFeaturesContext from '../../contexts/StudentFeaturesContext';
import NavItem from './NavItem';
import NavItems from './SidebarItemsConfig';
import UserActionsAvatar from './UserActionsAvatar';
import { getTrialCountDownMsg } from '../../generic/View/ViewCompoments';
import config from '../../../consts/config';
import useExternalIDPRoutePrefix from '../../../hooks/useExternalIDPRoutePrefix';
import { getCoursePageStyles } from '../../../screens/CourseOverviewPageV2/utils';
import MobileMenu, { isNavItemActive } from './MobileMenu';
import { isMobile } from 'react-device-detect';
import useStyling from '@recourseai/components/src/theme/useStyling';

const PADDING_X = '15px';
const PADDING_Y_MAIN_PANEL = '15px';
const PADDING_Y_SIDEBAR = '30px';
export const HEADER_HEIGHT = '65px';

const ChakraFramerBox = chakra(motion.div, {
    shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children',
});

interface DashboardPageLayoutProps {
    noPadding?: boolean;
    breadCrumbs?: {
        items: { title: string; url: string; isCurrent?: boolean }[];
        textColor?: BoxProps['textColor'];
    };
}

const SIDEBAR_COMPACT_WIDTH = '80px';
const SIDEBAR_EXPANDED_WIDTH = '215px';

const DashboardPageLayout: React.FC<DashboardPageLayoutProps> = ({
    children,
    noPadding,
    breadCrumbs,
}) => {
    const { isBase } = useStyling();
    const coursePageStyles = getCoursePageStyles(isMobile && isBase);

    const location = useLocation();
    const routePrefix = useExternalIDPRoutePrefix();

    const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
        defaultIsOpen: true,
    });
    const [openType, setOpenType] = useState<'layout' | 'float'>('layout');
    const [isExpanded, setIsExpanded] = useState(isOpen);

    const { features, trialData } = useContext(StudentFeaturesContext);

    return (
        <Flex h='100dvh' w='100vw' direction={isMobile ? 'column' : 'row'}>
            {isMobile && (
                <MobileMenu routePrefix={routePrefix} features={features} />
            )}
            {!isMobile && (
                <motion.div
                    initial={false}
                    animate={{
                        width: isOpen
                            ? SIDEBAR_EXPANDED_WIDTH
                            : SIDEBAR_COMPACT_WIDTH,
                    }}
                    transition={{ type: 'tween', duration: 0.3 }}
                    onAnimationStart={() => setIsExpanded(false)}
                    onAnimationComplete={() => setIsExpanded(isOpen)}
                    style={{
                        height: '100%',
                        flexShrink: 0,
                        flexGrow: 0,
                        position: 'fixed',
                        zIndex: 105,
                    }}
                >
                    <Box
                        h='full'
                        paddingX={PADDING_X}
                        paddingY={PADDING_Y_SIDEBAR}
                        bg='brand.black.900'
                        onMouseOver={() => {
                            onOpen();
                            if (!isOpen) {
                                setOpenType('float');
                            }
                        }}
                        onMouseLeave={() => {
                            if (openType === 'float') {
                                onClose();
                            }
                        }}
                    >
                        <Flex direction='column' h='full'>
                            <Link
                                as={RouterLink}
                                to={ROUTES.HOME}
                                px={2}
                                py={4}
                                mx={-2}
                                my={-4}
                                position='relative'
                                zIndex={1}
                            >
                                {/* Having two elements is preferred as changing the src dynamically would need to re-fetch the image*/}
                                <Image
                                    src={Logo}
                                    hidden={!isExpanded}
                                    h='22px'
                                    alt={config.REACT_APP_BRANDING_TITLE}
                                />
                                <Image
                                    src={LogoShort}
                                    hidden={isExpanded}
                                    h='22px'
                                    alt={config.REACT_APP_BRANDING_TITLE}
                                />
                            </Link>
                            <Center position='relative' h='90px'>
                                <IconButton
                                    aria-label='Open Sidebar'
                                    colorScheme='brand.black'
                                    borderRadius='full'
                                    size='xs'
                                    w='32px'
                                    h='32px'
                                    position='absolute'
                                    right={`calc(-${PADDING_X} - 16px)`}
                                    border='2px solid white'
                                    icon={
                                        <FontAwesomeIcon
                                            size='lg'
                                            icon={
                                                isOpen && openType === 'layout'
                                                    ? faChevronLeft
                                                    : faChevronRight
                                            }
                                        />
                                    }
                                    onMouseOver={e => e.stopPropagation()}
                                    onClick={() => {
                                        if (openType === 'layout') {
                                            onToggle();
                                        } else {
                                            onOpen();
                                        }
                                        setOpenType('layout');
                                    }}
                                />
                            </Center>
                            <VStack spacing={5} alignItems='stretch' px='10px'>
                                {NavItems.filter(item =>
                                    features.includes(item.feature),
                                ).map(item => {
                                    return (
                                        <NavItem
                                            key={item.route}
                                            route={item.route}
                                            isActive={isNavItemActive(
                                                item.route,
                                                location,
                                                routePrefix,
                                            )}
                                            icon={item.icon}
                                        >
                                            {isExpanded ? item.text : null}
                                        </NavItem>
                                    );
                                })}
                            </VStack>
                            <Spacer />
                            <Center>
                                <UserActionsAvatar />
                            </Center>
                        </Flex>
                    </Box>
                </motion.div>
            )}
            <ChakraFramerBox
                h='100%'
                flexGrow={1}
                overflow='auto'
                bg='brand.black.100'
                initial={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                transition={{ type: 'tween', duration: 0.3 }}
                animate={{
                    marginLeft: isMobile
                        ? '0px'
                        : isOpen && openType === 'layout'
                        ? SIDEBAR_EXPANDED_WIDTH
                        : SIDEBAR_COMPACT_WIDTH,
                }}
            >
                <Flex
                    justifyContent='space-between'
                    alignItems='center'
                    p={3}
                    h={HEADER_HEIGHT}
                >
                    {breadCrumbs && (
                        <Breadcrumb
                            mb='-1em'
                            textColor={breadCrumbs.textColor}
                            separator={
                                <FontAwesomeIcon icon={faChevronRight} />
                            }
                            {...coursePageStyles.breadCrumb}
                        >
                            {breadCrumbs.items.map(breadCrumb => (
                                <BreadcrumbItem key={breadCrumb.url}>
                                    <BreadcrumbLink
                                        as={RouterLink}
                                        to={breadCrumb.url}
                                        isCurrentPage={breadCrumb.isCurrent}
                                    >
                                        {breadCrumb.title}
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                            ))}
                        </Breadcrumb>
                    )}
                    <Spacer />
                    <HStack spacing={2} display={{ base: 'none', md: 'flex' }}>
                        {trialData.is_trial && (
                            <Center
                                mr={4}
                                px={4}
                                height={10}
                                bg='brand.gradient.cold.light'
                                color='white'
                                borderRadius='15px'
                            >
                                {getTrialCountDownMsg(
                                    trialData.seconds_remaining!,
                                )}
                            </Center>
                        )}
                        <ModalOpener Modal={UserFeedbackModal}>
                            <Button
                                bgColor='white'
                                leftIcon={
                                    <FontAwesomeIcon icon={faLightbulb} />
                                }
                            >
                                Leave us feedback
                            </Button>
                        </ModalOpener>
                    </HStack>
                </Flex>
                <Box
                    paddingX={noPadding ? undefined : `calc(2 * ${PADDING_X})`}
                    paddingY={noPadding ? undefined : PADDING_Y_MAIN_PANEL}
                    marginTop={`-${HEADER_HEIGHT}`}
                >
                    {children}
                </Box>
            </ChakraFramerBox>
        </Flex>
    );
};

export default DashboardPageLayout;
