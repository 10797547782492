import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import GroupRow from './GroupRow';
import LoadingSpinner from '../generic/LoadingSpinner';
import CentredComponent from '../../hocs/CentredComponent';
import { moocAPI } from '../../services';
import AddGroupButton from './actions/AddGroupButton';
import useAsyncError from '../../hooks/useAsyncError';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import AllowedActionsContext from '../contexts/AllowedActionsContext';
import PortfolioGroupMembersModal from '../modals/PortfolioGroupMembersModal';
import { RecordCaseButton, UploadCaseButton } from './actions/CreateCaseButton';
import { GROUP_NAME_ALREADY_EXISTS } from '../../consts/errors';

interface Props {
    groups: Group[] | null;
    setGroups: React.Dispatch<React.SetStateAction<Group[] | null>>;
    refetch: () => void;
}

const GroupsView: React.FC<Props> = ({ groups, setGroups, refetch }) => {
    const throwAsyncError = useAsyncError();
    const [groupInEdit, setGroupInEdit] = useState<Group | undefined>(
        undefined,
    );
    const [groupToDelete, setGroupToDelete] = useState<Group | undefined>(
        undefined,
    );
    const [groupToShowMembers, setGroupToShowMembers] = useState<
        Group | undefined
    >(undefined);
    const [groupMembers, setGroupMembers] = useState<
        PortfolioGroupMember[] | undefined
    >(undefined);
    const replaceById = (groups: Group[], updatedGroup: Group): Group[] => {
        const idx = groups!.findIndex(group => group.id === updatedGroup.id);
        return [
            ...groups!.slice(0, idx),
            updatedGroup,
            ...groups!.slice(idx + 1),
        ];
    };

    useEffect(() => {
        if (groupToShowMembers) {
            moocAPI
                .get(`portfolio/group/${groupToShowMembers.id}/members/`)
                .then(setGroupMembers)
                .catch(throwAsyncError);
        }
    }, [groupToShowMembers, throwAsyncError]);

    const deleteGroup = useCallback(
        (id: number) => {
            moocAPI
                .delete(`portfolio/group/${id}/`)
                .then(() =>
                    setGroups(old => old!.filter(group => group.id !== id)),
                )
                .catch(err => throwAsyncError(err));
        },
        [setGroups, throwAsyncError],
    );

    return (
        <Fragment>
            {groups && groups.length !== 0 ? (
                <Fragment>
                    {/* add mt for space between UserInfo and group buttons */}
                    <Row className='align-items-end mb-2 mt-4'>
                        <Col>
                            <AddGroupButton
                                size='sm'
                                placement='right'
                                onFormSubmit={(values, { setFieldError }) => {
                                    moocAPI
                                        .post('portfolio/groups/', {
                                            name: values.groupName,
                                        })
                                        .then(res => {
                                            setGroups(old => [res].concat(old));
                                            document.body.click();
                                        })
                                        .catch(error => {
                                            if (error.name === 'unique') {
                                                setFieldError(
                                                    'groupName',
                                                    GROUP_NAME_ALREADY_EXISTS,
                                                );
                                            } else {
                                                throwAsyncError(error);
                                            }
                                        });
                                }}
                            />
                        </Col>
                        <Col xs='auto' className='ml-auto'>
                            <RecordCaseButton className='mx-2' />
                            <UploadCaseButton className='mx-2' />
                        </Col>
                    </Row>
                    {groups.map((group, index) => {
                        return (
                            <AllowedActionsContext.Provider
                                value={group.user_permissions}
                                key={index}
                            >
                                <GroupRow
                                    group={group}
                                    isEditMode={groupInEdit?.id === group.id}
                                    onDelete={() =>
                                        group.cases.length > 0
                                            ? setGroupToDelete(group)
                                            : deleteGroup(group.id)
                                    }
                                    onEdit={() => setGroupInEdit(group)}
                                    onCancelEdit={() =>
                                        setGroupInEdit(undefined)
                                    }
                                    onShare={() => {
                                        moocAPI
                                            .patch(
                                                `portfolio/group/${group.id}/`,
                                                { status: 'shared' },
                                            )
                                            .then(res => {
                                                setGroups(old =>
                                                    replaceById(old!, res),
                                                );
                                                setGroupToShowMembers(res);
                                            })
                                            .catch(err => throwAsyncError(err));
                                    }}
                                    onSeeMembers={() => {
                                        setGroupToShowMembers(group);
                                    }}
                                    onSubmit={(values, { setFieldError }) => {
                                        moocAPI
                                            .patch(
                                                `portfolio/group/${group.id}/`,
                                                {
                                                    name: values.groupName,
                                                },
                                            )
                                            .then(res => {
                                                setGroups(old =>
                                                    replaceById(old!, res),
                                                );
                                                setGroupInEdit(undefined);
                                            })
                                            .catch(error => {
                                                if (error.name === 'unique') {
                                                    setFieldError(
                                                        'groupName',
                                                        GROUP_NAME_ALREADY_EXISTS,
                                                    );
                                                } else {
                                                    throwAsyncError(error);
                                                }
                                            });
                                    }}
                                />
                            </AllowedActionsContext.Provider>
                        );
                    })}
                    {groupToDelete && (
                        <DeleteConfirmationModal
                            resourceName={groupToDelete.name}
                            show={groupToDelete !== undefined}
                            onClose={() => setGroupToDelete(undefined)}
                            validationText='delete-group'
                            message={
                                'Deleting the group will also remove all its cases!'
                            }
                            onDelete={() => deleteGroup(groupToDelete.id)}
                        />
                    )}
                    {groupToShowMembers && (
                        <AllowedActionsContext.Provider
                            value={groupToShowMembers.user_permissions}
                        >
                            <PortfolioGroupMembersModal
                                open={groupToShowMembers !== undefined}
                                onClose={() => setGroupToShowMembers(undefined)}
                                invitationLink={
                                    groupToShowMembers.invitation_link
                                }
                                members={groupMembers}
                                onMakePrivate={() => {
                                    moocAPI
                                        .patch(
                                            `portfolio/group/${groupToShowMembers.id}/`,
                                            {
                                                status: 'private',
                                            },
                                        )
                                        .then(res => {
                                            setGroupToShowMembers(undefined);
                                            setGroups(old =>
                                                replaceById(old!, res),
                                            );
                                        })
                                        .catch(throwAsyncError);
                                }}
                                onDeleteMember={(memberId: number) => {
                                    moocAPI
                                        .delete(
                                            `portfolio/group/${groupToShowMembers.id}/members/${memberId}/`,
                                        )
                                        .then(() =>
                                            setGroupMembers(old =>
                                                old?.filter(
                                                    member =>
                                                        member.user.id !==
                                                        memberId,
                                                ),
                                            ),
                                        )
                                        .catch(throwAsyncError);
                                }}
                                onLeave={() => {
                                    moocAPI
                                        .delete(
                                            `portfolio/group/${groupToShowMembers.id}/members/leave/`,
                                        )
                                        // Refetch becasue cases previously in this group will be transferred to another
                                        .then(() => refetch())
                                        .catch(throwAsyncError);
                                }}
                            />
                        </AllowedActionsContext.Provider>
                    )}
                </Fragment>
            ) : groups === null ? (
                <CentredComponent component={<LoadingSpinner />} />
            ) : (
                <Fragment>
                    <CentredComponent
                        component={<UploadCaseButton />}
                        className='mb-3'
                    />
                    <Alert variant='secondary'>
                        You currently have no groups
                    </Alert>
                </Fragment>
            )}
        </Fragment>
    );
};

export default GroupsView;
