import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { getChatStyles } from '../../theme/consts';

interface Props {
    text: string;
    isTextMode: boolean;
}

const UserMessage: React.FC<Props> = ({ text, isTextMode }) => {
    const styles = getChatStyles(isTextMode);

    return (
        <Text
            className='fs-mask'
            color='white'
            wordBreak='break-word'
            fontWeight='bold'
            m='0'
            p='10px'
            borderRadius='10px'
            maxW='80%'
            {...styles.userMessage}
        >
            {text}
        </Text>
    );
};

export default UserMessage;
