import React from 'react';
import { Box, BoxProps, Center, Divider, Flex, Text } from '@chakra-ui/react';
import { Event, InteractionStageFeedback } from './types';
import getHumanReadableFromDate from '../../../utils/dateUtils';
import SendFeedback from '../../activities/consultation/components/SendFeedback';

const MsgBox: React.FC<BoxProps> = props => {
    return (
        <Box className='fs-mask' borderRadius='15px' p={3} my={2} {...props} />
    );
};

type TranscriptEventFC = React.FC<{ event: Event }>;

const ResumeEvent: TranscriptEventFC = ({ event }) => (
    <Center alignItems='start'>
        <Divider
            my={2}
            opacity={0.8}
            borderColor='brand.black.500'
            borderBottomWidth='3px'
        />
        <Box mx={3}>
            <Text whiteSpace='nowrap' mb={1}>
                Conversation resumed
            </Text>
            <Text mb={0}>{getHumanReadableFromDate(event.created_at)}</Text>
        </Box>
        <Divider
            my={2}
            opacity={0.8}
            borderColor='brand.black.500'
            borderBottomWidth='3px'
        />
    </Center>
);

const UserMessageEvent: TranscriptEventFC = ({ event }) => (
    <Flex flex='1' justifyContent='end'>
        <MsgBox bg='brand.black.800' color='white' maxWidth='80%'>
            {event.user_action!.payload.text}
        </MsgBox>
    </Flex>
);

const AgentMessage: TranscriptEventFC = ({ event }) => (
    <Flex flex='1' justifyContent='start' alignItems='center'>
        <SendFeedback actionId={event.agent_action!.id} showButton={true} />
        <MsgBox bg='brand.black.200' maxWidth='80%'>
            {event.agent_action!.payload.textual.text}
        </MsgBox>
    </Flex>
);

const Transcript: React.FC<InteractionStageFeedback<any>> = ({ events }) => {
    return (
        <Flex flex={1} direction='column' p={2}>
            {events.map(ev => {
                if (ev.user_action) {
                    if (ev.user_action.action_type === 'resume') {
                        return (
                            <ResumeEvent key={ev.user_action?.id} event={ev} />
                        );
                    }
                    if (
                        ev.user_action.action_type === 'utterance' &&
                        ev.user_action.payload.text
                    ) {
                        return (
                            <UserMessageEvent
                                key={ev.user_action.id}
                                event={ev}
                            />
                        );
                    }
                }
                if (ev.agent_action) {
                    if (
                        ev.agent_action.action_type === 'utterance' &&
                        ev.agent_action.payload.textual.text
                    ) {
                        return (
                            <AgentMessage key={ev.agent_action.id} event={ev} />
                        );
                    }
                }
                return null;
            })}
        </Flex>
    );
};

export default Transcript;
