import React, { Fragment, useContext, useEffect, useState } from 'react';
import './App.css';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import ROUTES from './consts/routes';
import RenderWithLoading from './components/generic/RenderWithLoading';
import { doesTokenExpireSoon } from './utils/tokenUtils';
import queryString from 'query-string';
import CertificatePage from './screens/CertificatePage';
import { authService, keycloak, moocAPI } from './services';
import useAsyncError from './hooks/useAsyncError';
import StudentMoocEnabledFeaturesContext, {
    MoocFeature,
    TrialData,
} from './components/contexts/StudentFeaturesContext';
import IDPS from './consts/external_idps';
import * as Sentry from '@sentry/browser';
import { useMixpanel } from './components/contexts/MixpanelContext';
import { QueryCacheProvider } from './components/contexts/QueryCache';
import TrialExpiredModal from './components/modals/TrialAccessExpiredModal';
import { Home } from './screens/Home';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import PageNotFoundPage from './screens/PageNotFoundPage';
import OrganisationRegistrationPage from './screens/OrganisationRegistrationPage';
import GroupOverviewPage from './screens/GroupOverviewPage';
import CaseOverviewPage from './screens/CaseOverviewPage';
import UploadCasePage from './screens/UploadCasePage';
import CoursesPageV2 from './screens/CoursesPageV2';
import TeamsPageV2 from './screens/TeamsPageV2';
import CoursePageV2 from './screens/CourseOverviewPageV2';
import config from './consts/config';
import useExternalIDPRoutePrefix from './hooks/useExternalIDPRoutePrefix';
import InteractionPage from './pages/InteractionPage';
import CertificationsPage from './screens/CertificationsPage';
import Leaderboard from './screens/Leaderboard';
import CoursesInProgressPage from './screens/CoursesInProgressPage/CoursesInProgressPage';

const AuthenticatedRoute = ({
    path,
    exact = false,
    moocFeature = undefined,
    routeComponent,
}: {
    path: string;
    exact?: boolean;
    moocFeature?: MoocFeature;
    routeComponent: JSX.Element;
    requiresSubscription?: boolean;
}): JSX.Element => {
    const { features } = useContext(StudentMoocEnabledFeaturesContext);
    const { idpHint } = useParams<{ idpHint: string }>();
    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps: any): JSX.Element | null => {
                if (keycloak.authenticated) {
                    if (moocFeature) {
                        return (
                            <RenderWithLoading hasLoaded={!!features}>
                                {!!features &&
                                features.includes(moocFeature) ? (
                                    routeComponent
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: `/${ROUTES.PAGE_NOT_FOUND}`,
                                        }}
                                    />
                                )}
                            </RenderWithLoading>
                        );
                    }
                    return routeComponent;
                }

                keycloak.login({
                    redirectUri: window.location.href,
                    idpHint: IDPS.includes(idpHint) ? idpHint : undefined,
                });
                return null;
            }}
        />
    );
};

const ExternalIDPRouteWrapper: React.FC = ({ children }) => {
    return (
        <Switch>
            <Route path='/:idpHint?'>{children}</Route>
        </Switch>
    );
};

const RouteWrapper: React.FC = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { features } = useContext(StudentMoocEnabledFeaturesContext);

    const { path } = useRouteMatch();
    const { idpHint } = useParams<{ idpHint: string }>();
    const mixpanel = useMixpanel();
    const routePrefix = useExternalIDPRoutePrefix();

    if (keycloak.authenticated) {
        const external_idp = (keycloak?.tokenParsed as any)?.external_idp;
        if (external_idp && idpHint !== external_idp) {
            const newLocation = { ...location };
            newLocation['pathname'] = `/${external_idp}${location.pathname}`;
            newLocation['hash'] = ''; // Hack because the keycloak URL cleanup does not happen in time for this
            return <Redirect to={newLocation} />;
        }
    }

    // Grab payment success flag, so we can log to mixpanel then redirect to same URL without this param
    if ('payment_success' in params) {
        const paymentSuccess = params['payment_success']?.toString() ?? '';
        if (paymentSuccess === 'false') {
            mixpanel?.track('payment_cancelled_or_failed');
        } else if (paymentSuccess === 'true') {
            mixpanel?.track('payment_success');
        }

        const newParams = { ...params };
        delete newParams['payment_success'];

        const search = queryString.stringify(newParams);
        return <Redirect to={[location.pathname, '?', search].join('')} />;
    }

    return (
        <div
            id='route-wrapper'
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            <Switch>
                <Route
                    exact
                    path={`${routePrefix}/${ROUTES.CERTIFICATE}/:certificateId(\\w+)+/`}
                    render={(): JSX.Element => <CertificatePage />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.COURSES}`}
                    moocFeature='courses'
                    routeComponent={<CoursesPageV2 />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.MY_PROGRESS}`}
                    moocFeature='courses'
                    routeComponent={<CoursesInProgressPage />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.ACTIVITY}/:activityId(\\d+)+/`}
                    moocFeature='courses'
                    routeComponent={<CoursesPageV2 />}
                />
                <Redirect
                    exact
                    from={`${routePrefix}/${ROUTES.DEPRECATED_COURSE_OVERVIEW}/:courseId(\\d+)+/${ROUTES.ACTIVITY}/:activityId(\\d+)+/`}
                    to={`${routePrefix}/${ROUTES.COURSES}/:courseId(\\d+)+/${ROUTES.ACTIVITY}/:activityId(\\d+)+/`}
                />
                <Redirect
                    exact
                    from={`${routePrefix}/${ROUTES.DEPRECATED_COURSE_OVERVIEW}/:courseId(\\d+)+/`}
                    to={`${routePrefix}/${ROUTES.COURSES}/:courseId(\\d+)+/`}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.COURSES}/:courseId(\\d+)+/(${ROUTES.ACTIVITY})?/:activityId(\\d+)?/`}
                    moocFeature='courses'
                    routeComponent={
                        <CoursePageV2
                            breadCrumbs={[
                                { title: 'Courses', url: `/${ROUTES.COURSES}` },
                            ]}
                        />
                    }
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.PORTFOLIO}`}
                    moocFeature='portfolio'
                    routeComponent={<TeamsPageV2 />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.UPLOAD_CASE}`}
                    moocFeature='portfolio'
                    routeComponent={<UploadCasePage />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.GROUP_OVERVIEW}/:groupId(\\d+)+/`}
                    moocFeature='portfolio'
                    routeComponent={<GroupOverviewPage />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.CASE_OVERVIEW}/:caseId(\\d+)+/`}
                    moocFeature='portfolio'
                    routeComponent={<CaseOverviewPage />}
                />
                <Redirect
                    exact
                    from={`${routePrefix}/${ROUTES.INSIGHTS}`}
                    to={`${routePrefix}/${ROUTES.CERTIFICATIONS}`}
                />
                <Redirect
                    exact
                    from={`${routePrefix}/${ROUTES.INSIGHTS}/${ROUTES.COURSES}/:courseId(\\d+)+/(${ROUTES.ACTIVITY})?/:activityId(\\d+)?/`}
                    to={`${routePrefix}/${ROUTES.CERTIFICATIONS}/${ROUTES.COURSES}/:courseId(\\d+)+/(${ROUTES.ACTIVITY})?/:activityId(\\d+)?/`}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.CERTIFICATIONS}`}
                    moocFeature='courses'
                    routeComponent={<CertificationsPage />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.LEADERBOARD}`}
                    moocFeature='leaderboard'
                    routeComponent={<Leaderboard />}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}/${ROUTES.CERTIFICATIONS}/${ROUTES.COURSES}/:courseId(\\d+)+/(${ROUTES.ACTIVITY})?/:activityId(\\d+)?/`}
                    moocFeature='courses'
                    routeComponent={
                        <CoursePageV2
                            breadCrumbs={[
                                {
                                    title: 'Certifications',
                                    url: `/${ROUTES.CERTIFICATIONS}`,
                                },
                            ]}
                        />
                    }
                />
                <Route
                    exact
                    path={`${routePrefix}/${ROUTES.LOGIN}`}
                    render={(): JSX.Element => {
                        if (!keycloak.authenticated) {
                            keycloak.login({
                                idpHint: IDPS.includes(idpHint)
                                    ? idpHint
                                    : undefined,
                            });
                            return <Fragment />;
                        } else {
                            return <Redirect to={ROUTES.HOME} />;
                        }
                    }}
                />
                <Route
                    exact
                    path={`${path}/${ROUTES.REGISTRATION}`}
                    component={OrganisationRegistrationPage}
                />
                <Route
                    exact
                    path={`${routePrefix}/${ROUTES.LOGOUT}`}
                    render={(): JSX.Element => {
                        authService.logout();
                        return <Fragment />;
                    }}
                />
                <AuthenticatedRoute
                    exact
                    path={`${routePrefix}${ROUTES.HOME}`}
                    routeComponent={
                        <RenderWithLoading hasLoaded={!!features}>
                            <Home />
                        </RenderWithLoading>
                    }
                />
                {/* Temporary route */}
                {/* Route declaration have to be at top level, don't nest inside <Fragment>*/}
                {(config.REACT_APP_ENV !== 'production' ||
                    config.REACT_APP_BETA_FEATURES?.toLowerCase() ===
                        'true') && (
                    <AuthenticatedRoute
                        exact
                        path={`${routePrefix}/${ROUTES.COURSES}/:courseId(\\d+)/${ROUTES.ACTIVITY}/:activityId(\\d+)/player`}
                        routeComponent={<InteractionPage />}
                    />
                )}
                {(config.REACT_APP_ENV !== 'production' ||
                    config.REACT_APP_BETA_FEATURES?.toLowerCase() ===
                        'true') && (
                    <AuthenticatedRoute
                        exact
                        path={`${routePrefix}/${ROUTES.ACTIVITY}/:activityId(\\d+)/player`}
                        routeComponent={<InteractionPage />}
                    />
                )}
                <Route
                    component={(): JSX.Element => {
                        return <PageNotFoundPage />;
                    }}
                />
            </Switch>
        </div>
    );
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 30,
        },
    },
});

const App: React.FC = () => {
    const [authChecked, setAuthChecked] = useState<boolean>(false);
    const [moocFeatures, setMoocFeatures] = useState<MoocFeature[] | null>(
        null,
    );
    const [trialData, setTrialData] = useState<TrialData | null>(null);
    const throwAsyncError = useAsyncError();
    const location = useLocation();

    useEffect(() => {
        keycloak.onAuthSuccess = () => {
            keycloak.loadUserProfile().catch(e => Sentry.captureException(e));

            Promise.all([
                moocAPI.get('products/mooc-features/'),
                moocAPI.get('student/user'),
            ])
                .then(([features, userData]) => {
                    authService.initUserLoggers(userData);
                    setMoocFeatures(features);
                    setTrialData(userData.trial_data);
                    queryClient.setQueryData(['user-data'], userData);
                })
                .catch(err => {
                    // Hack to allow access to the registration page
                    if (
                        location.pathname.includes('register') &&
                        new URLSearchParams(location.search).get('invitation')
                    ) {
                        setMoocFeatures([]);
                        setTrialData({ is_trial: false });
                    } else {
                        throwAsyncError(err);
                    }
                });
        };

        keycloak.onAuthError = () => {
            // Remove tokens that were saved upon registration
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        };

        keycloak.onAuthLogout = () => {
            // Remove tokens that were saved upon registration
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            authService.clearUserLoggers();
        };

        keycloak
            .init({
                // Tokens may be saved in localstorage soon after registration
                token: localStorage.getItem('access_token') || undefined,
                refreshToken:
                    localStorage.getItem('refresh_token') || undefined,
                onLoad: 'check-sso',
                checkLoginIframe: false,
            })
            .then(authenticated => {
                setAuthChecked(true);
            })
            .catch(throwAsyncError);
    }, [throwAsyncError]);

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.isTokenExpired()) {
            const hoursUntilExp = Number(
                config.REACT_APP_HOURS_UNTIL_SESSION_EXPIRATION,
            );
            if (
                doesTokenExpireSoon(keycloak.refreshTokenParsed, hoursUntilExp)
            ) {
                authService.logout();
            }
        }
    }, [location]);

    //
    // NOTE: ordering matters, if you define a vague route, followed by a more specific route, the latter will not be seen
    // e.g. /course/1/ && /course/1/activity/1/
    //
    return (
        <QueryClientProvider client={queryClient}>
            <QueryCacheProvider>
                <RenderWithLoading
                    hasLoaded={
                        authChecked &&
                        (!keycloak.authenticated ||
                            (moocFeatures !== null && trialData !== null))
                    }
                >
                    <StudentMoocEnabledFeaturesContext.Provider
                        value={{
                            features: moocFeatures!,
                            setFeatures: setMoocFeatures,
                            trialData: trialData!,
                            setTrialData: setTrialData,
                        }}
                    >
                        <ExternalIDPRouteWrapper>
                            <RouteWrapper />
                        </ExternalIDPRouteWrapper>
                    </StudentMoocEnabledFeaturesContext.Provider>
                    {trialData && <TrialExpiredModal trialData={trialData} />}
                </RenderWithLoading>
            </QueryCacheProvider>
        </QueryClientProvider>
    );
};

export default App;
