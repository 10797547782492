import React, { Fragment, ReactNode } from 'react';
import {
    Avatar,
    Box,
    Center,
    Circle,
    Heading,
    HStack,
    Spacer,
    Stack,
    Text,
    TextProps,
    VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { getCaretIcon, getMedalStyles, getStatusInfoBoxText } from './utils';
import { moocAPI } from '../../services';
import { useQuery } from '@tanstack/react-query';
import { leaderboardEntryHighlightStyles } from '@recourseai/components/src/theme/consts';

const BORDER_RADIUS = 15;

interface LeaderboardEntryType {
    id: number;
    name: string;
    points: number;
    position: number | null;
    previous_position: number | null;
}

export interface Leaderboard {
    leaderboard_top?: LeaderboardEntryType[];
    student_leaderboard_entry?: LeaderboardEntryType;
    top_n: number;
}

interface InfoBoxProps {
    bg: TextProps['bg'];
    children: ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ bg, children }) => {
    return (
        <Text bg={bg} p={2} borderRadius={BORDER_RADIUS} width='fit-content'>
            {children}
        </Text>
    );
};

export interface LeaderboardEntryProps extends LeaderboardEntryType {
    highLight?: 'dark' | 'light' | 'none';
    positionDisplay?: 'circle' | 'number';
}

const LeaderboardEntry: React.FC<LeaderboardEntryProps> = ({
    name,
    points,
    position,
    previous_position,
    highLight = 'none',
    positionDisplay = 'circle',
}) => {
    return (
        <HStack
            borderRadius={BORDER_RADIUS}
            p='10px'
            fontSize='lg'
            {...leaderboardEntryHighlightStyles[highLight]}
        >
            <Box minW='0.7rem'>
                {getCaretIcon(position!, previous_position)}
            </Box>
            {positionDisplay === 'circle' ? (
                <Circle
                    size='1.5rem'
                    border='2px'
                    fontSize='0.8rem'
                    {...getMedalStyles(position!)}
                >
                    {position}
                </Circle>
            ) : (
                <Center w='1.5rem' fontSize='0.8rem'>
                    {position}
                </Center>
            )}
            <Avatar
                boxSize='1.6rem'
                size='sm'
                name={name}
                bg='brand.gradient.full'
                fontWeight='light'
                color='white'
            />
            <Text>{name}</Text>
            <Spacer />
            <Text>{points}</Text>
        </HStack>
    );
};

const LeaderboardEntries: React.FC<{ leaderboard: Leaderboard }> = ({
    leaderboard,
}) => {
    return (
        <Box>
            <HStack
                color='gray.600'
                justifyContent='space-between'
                fontSize='xx-small'
                pl={6}
                pr={4}
                sx={{ '& > *': { m: 0, fontWeight: 'bold' } }}
            >
                <Text>RANK</Text>
                <Text>NAME</Text>
                <Spacer />
                <Text>POINTS</Text>
            </HStack>
            {!leaderboard.leaderboard_top ? (
                <VStack bg='white' borderRadius={BORDER_RADIUS} p={10} gap={5}>
                    <FontAwesomeIcon
                        icon={faTrophy}
                        color='orange'
                        opacity='25%'
                        size='5x'
                    />
                    <Text textAlign='center' lineHeight={1}>
                        <b>The leaderboard remains unclaimed!</b>
                        <br />
                        The race is still wide open - keep building your score
                        and take the lead before anyone else does.
                    </Text>
                </VStack>
            ) : (
                <Stack>
                    {leaderboard.leaderboard_top.map(leaderboardEntry => {
                        const highLight =
                            leaderboardEntry.id ===
                            leaderboard.student_leaderboard_entry?.id
                                ? 'dark'
                                : leaderboardEntry.position! < 4
                                ? 'light'
                                : 'none';
                        return (
                            <LeaderboardEntry
                                key={leaderboardEntry.id}
                                highLight={highLight}
                                {...leaderboardEntry}
                            />
                        );
                    })}
                </Stack>
            )}
        </Box>
    );
};

const UserEntryOnly: React.FC<LeaderboardEntryType> = props => {
    return (
        <Box>
            <Text fontSize='xx-small' color='gray.600' fontWeight='bold' mb={0}>
                ONLY VISIBLE TO ME
            </Text>
            {!props.position ? (
                <Box
                    bg='black'
                    color='white'
                    borderRadius={BORDER_RADIUS}
                    p={3}
                    fontSize='sm'
                    textAlign='center'
                >
                    You do not have enough points. Please complete more
                    activities to see your ranking.
                </Box>
            ) : (
                <LeaderboardEntry
                    {...props}
                    highLight='dark'
                    positionDisplay='number'
                />
            )}
        </Box>
    );
};

const LeaderBoardView = () => {
    const { data: leaderboard, isSuccess: leaderboardLoaded } = useQuery<
        Leaderboard
    >(['leaderboard'], () => moocAPI.get('leaderboard'));

    const isUserInTopScores = !!leaderboard?.leaderboard_top?.find(
        entry => entry.id === leaderboard.student_leaderboard_entry?.id,
    );
    const statusInfoBoxText = getStatusInfoBoxText(
        leaderboard!,
        isUserInTopScores,
    );

    return (
        <Fragment>
            {leaderboardLoaded && (
                <Box p={5}>
                    <Heading size='md' mb={2}>
                        Group leaderboard
                    </Heading>
                    <InfoBox bg='white'>
                        The group leaderboard displays only the top{' '}
                        <b>{leaderboard.top_n}</b> performers, so your ranking
                        appears only if you are in that group. You need to reach
                        the minimum threshold to both enter the leaderboard and
                        see your own ranking.
                    </InfoBox>
                    {statusInfoBoxText && (
                        <InfoBox bg={statusInfoBoxText.bg}>
                            {statusInfoBoxText.text}
                        </InfoBox>
                    )}
                    <Stack width={['100%', null, '75%', '50%']} mx='auto'>
                        <LeaderboardEntries leaderboard={leaderboard} />
                        {!isUserInTopScores &&
                            leaderboard.student_leaderboard_entry && (
                                <UserEntryOnly
                                    {...leaderboard.student_leaderboard_entry}
                                />
                            )}
                    </Stack>
                </Box>
            )}
        </Fragment>
    );
};

export default LeaderBoardView;
