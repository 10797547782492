import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { moocAPI } from '../services';
import DashboardPageLayout from '../components/layouts/DashboardPageLayout';
import PortfolioPage from './PortfolioPage';
import useAsyncError from '../hooks/useAsyncError';
import RenderWithLoading from '../components/generic/RenderWithLoading';
import UserInfo from './CoursesPageV2/UserInfo';

export const TeamsPageV2: React.FC = () => {
    const throwAsyncError = useAsyncError();
    const { data: groups, isSuccess, refetch } = useQuery(
        ['portfolio', 'groups'],
        () => moocAPI.get('portfolio/groups/'),
        { onError: throwAsyncError },
    );
    const queryClient = useQueryClient();

    return (
        <DashboardPageLayout>
            <UserInfo containerProps={{ mt: 10 }} />
            <RenderWithLoading hasLoaded={isSuccess}>
                <PortfolioPage
                    groups={groups}
                    setGroups={updater =>
                        queryClient.setQueryData(
                            ['portfolio', 'groups'],
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            updater,
                        )
                    }
                    refetch={refetch}
                />
            </RenderWithLoading>
        </DashboardPageLayout>
    );
};

export default TeamsPageV2;
