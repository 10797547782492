import React, { CSSProperties } from 'react';
import { Navbar, Container, Row, Image } from 'react-bootstrap';
import config from '../../consts/config';
import NoColonFullLogo from '../../images/logo_full_no_colon.svg';

interface Props {
    height?: number;
    position?: CSSProperties['position'];
    showLogoAboveFooter?: boolean;
}

const Footer: React.FC<Props> = ({
    height,
    position = 'absolute',
    showLogoAboveFooter = false,
}) => {
    return (
        <Navbar
            bg='light'
            style={{
                bottom: 0,
                left: 0,
                right: 0,
                height: height,
                position: position,
            }}
        >
            {showLogoAboveFooter && (
                <Image
                    src={NoColonFullLogo}
                    style={{
                        position: 'absolute',
                        height: '20px',
                        right: '20px',
                        top: '-40px',
                    }}
                />
            )}
            <Container
                style={{
                    flexDirection: 'column',
                    padding: '0 20px',
                }}
            >
                <Row style={{ flex: 1, marginBottom: 0 }}>
                    <Navbar.Text style={{ color: 'var(--dark-grey)' }}>
                        {config.REACT_APP_BUILD_YEAR} Recourse AI LTD. | All
                        rights reserved
                        <a
                            href={`mailto:${config.REACT_APP_BRANDING_SUPPORT_EMAIL}`}
                        >
                            {` | ${config.REACT_APP_BRANDING_SUPPORT_EMAIL}`}
                        </a>
                    </Navbar.Text>
                </Row>
                {config.REACT_APP_BRANDING_CUSTOM_FOOTER && (
                    <Row>
                        <Navbar.Text
                            className='py-0'
                            style={{ fontSize: '0.9rem' }}
                        >
                            {config.REACT_APP_BRANDING_CUSTOM_FOOTER}
                        </Navbar.Text>
                    </Row>
                )}
            </Container>
        </Navbar>
    );
};

export default Footer;
