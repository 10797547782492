import React from 'react';
import DashboardPageLayout from '../components/layouts/DashboardPageLayout';
import Section, { COURSE_CARD_SPACING } from './CoursesPageV2/Section';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Img,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import CourseCard from '../components/courses/CourseCardV2';
import { useCoursesQueries } from './CoursesPageV2';
import { Link as RouterLink } from 'react-router-dom';
import CertificationsGraphicSRC from '../images/certifications-graphic.jpg';
import ROUTES from '../consts/routes';
import { useCourseCardSize } from '../components/courses/CourseCardV2/utils';
import Header from './CoursesInProgressPage/Header';

const certificationsCourseUrlBuilder = (course: Course) =>
    `/${ROUTES.CERTIFICATIONS}/courses/${course.id}`;

const CertificationsPage: React.FC = () => {
    const [completedCoursesQuery] = useCoursesQueries(['completed']);
    const skeletonItemSize = useCourseCardSize('completed');

    return (
        <DashboardPageLayout noPadding>
            <Header heading='Certifications' />
            <Box paddingY={5} paddingX={10}>
                {completedCoursesQuery.isLoading ||
                completedCoursesQuery.data?.length ? (
                    <Section
                        heading={<Box mt={4}>Completed courses</Box>}
                        isLoaded={completedCoursesQuery.isSuccess}
                        data={completedCoursesQuery.data}
                        skeletonItemSize={skeletonItemSize}
                        wrapContent
                    >
                        <Flex gap={COURSE_CARD_SPACING} wrap='wrap'>
                            {completedCoursesQuery.data?.length &&
                                completedCoursesQuery?.data.map((course, i) => (
                                    <CourseCard
                                        key={i}
                                        course={course}
                                        variant='completed'
                                        courseUrlBuilder={
                                            certificationsCourseUrlBuilder
                                        }
                                    />
                                ))}
                        </Flex>
                    </Section>
                ) : (
                    <Center minH='350px' bg='white' borderRadius='lg'>
                        <Stack
                            spacing={10}
                            direction={['column', null, null, 'row']}
                            p={10}
                        >
                            <Center>
                                <Img
                                    objectFit='contain'
                                    height='110px'
                                    src={CertificationsGraphicSRC}
                                />
                            </Center>
                            <VStack alignItems='start' spacing={8}>
                                <Heading>
                                    You can find your learning certifications
                                    here
                                </Heading>
                                <Text>
                                    Currently you don&apos;t have any
                                    certifications. Please visit{' '}
                                    <Link
                                        as={RouterLink}
                                        to={`/${ROUTES.COURSES}`}
                                    >
                                        Courses
                                    </Link>{' '}
                                    to get started.
                                </Text>
                                <Button
                                    as={RouterLink}
                                    to={`/${ROUTES.COURSES}`}
                                    colorScheme='brand.black'
                                    size='lg'
                                    _hover={{
                                        color: 'white',
                                    }}
                                >
                                    Courses
                                </Button>
                            </VStack>
                        </Stack>
                    </Center>
                )}
            </Box>
        </DashboardPageLayout>
    );
};

export default CertificationsPage;
