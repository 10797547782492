import React, { Fragment } from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Section } from './types';

const FeedbackOverview: React.FC<Omit<
    Section['feedback'],
    'awarded_points'
>> = ({ available_points, generated_feedback, score }) => {
    return (
        <Fragment>
            {(!!available_points || !!generated_feedback) && (
                <Heading fontSize='3xl'>
                    {available_points ? (
                        <Fragment>Overall Score {score}%</Fragment>
                    ) : (
                        <Fragment>Feedback Overview</Fragment>
                    )}
                </Heading>
            )}
            {generated_feedback && (
                <Text whiteSpace='pre-line'>{generated_feedback}</Text>
            )}
        </Fragment>
    );
};

export default FeedbackOverview;
