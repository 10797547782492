import React, { Fragment } from 'react';
import {
    Box,
    BoxProps,
    Center,
    Heading,
    Skeleton,
    Spacer,
} from '@chakra-ui/react';
import UserActionsAvatar from '../../components/layouts/DashboardPageLayout/UserActionsAvatar';
import { moocAPI } from '../../services';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';

interface Props {
    containerProps: BoxProps;
}

export default function UserInfo({ containerProps }: Props) {
    const { data: userData, isSuccess: isUserDataReady } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
    );

    return (
        <Box
            // mt is needed on desktop to avoid user avatar icon from overlapping feedback button after removing tab icons
            // on mobile it should be avoided otherwise there is too much space between navbar and user info
            {...containerProps}
            mt={isMobile ? 0 : containerProps['mt'] ?? 10}
            py={containerProps['py'] ?? 4}
            borderColor={containerProps['borderColor'] ?? 'gray.400'}
            borderBottomWidth={containerProps['borderBottomWidth'] ?? '1px'}
        >
            <Skeleton isLoaded={isUserDataReady}>
                <Center justifyContent={isMobile ? 'flex-start' : undefined}>
                    <Heading
                        size='lg'
                        as='h2'
                        fontWeight='normal'
                        className='fs-exclude'
                    >
                        {isUserDataReady
                            ? `Welcome, ${userData.first_name} ${userData.last_name}`
                            : 'Welcome'}
                    </Heading>
                    {!isMobile && (
                        <Fragment>
                            <Spacer />
                            <UserActionsAvatar />
                        </Fragment>
                    )}
                </Center>
            </Skeleton>
        </Box>
    );
}
