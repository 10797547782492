import React, { Fragment } from 'react';
import {
    Heading,
    HeadingProps,
    Text,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Accordion,
} from '@chakra-ui/react';
import { Section } from './types';

const CollapsibleFeedback: React.FC<Section & {
    accordionBtnFontSize?: HeadingProps['size'];
    accordionBtnFontWeigth?: HeadingProps['fontWeight'];
}> = ({
    title,
    description,
    feedback,
    sections,
    accordionBtnFontSize = '3xl',
    accordionBtnFontWeigth,
}) => {
    return (
        <AccordionItem key={title}>
            <h2>
                <AccordionButton>
                    <Heading
                        flex='1'
                        textAlign='left'
                        fontSize={accordionBtnFontSize}
                        fontWeight={accordionBtnFontWeigth}
                    >
                        {title}
                        {feedback && (
                            <Fragment>
                                {!!feedback.available_points &&
                                    ` (${feedback.awarded_points} / ${feedback.available_points})`}
                            </Fragment>
                        )}
                    </Heading>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {feedback && (
                    <Fragment>
                        <Text mb={2} whiteSpace='pre-line' m={0}>
                            {description}
                        </Text>
                        <Text whiteSpace='pre-line' m={0}>
                            {feedback.generated_feedback}
                        </Text>
                    </Fragment>
                )}
                {sections && (
                    <Accordion defaultIndex={[0]} allowMultiple>
                        {sections?.map(section => (
                            <CollapsibleFeedback
                                key={section.title}
                                {...section}
                                accordionBtnFontSize='1.55rem'
                            />
                        ))}
                    </Accordion>
                )}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default CollapsibleFeedback;
