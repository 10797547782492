import React from 'react';
import { Heading, Stack, Text, Button, Box, Center } from '@chakra-ui/react';
import DashboardPageLayout from '../../components/layouts/DashboardPageLayout';
import {
    CarouselSectionActivities,
    CollapsibleSection,
} from '../CoursesPageV2/Section';
import { useCoursesQueries } from '../CoursesPageV2';
import { Link as RouterLink } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import Header from './Header';
import { useActivities } from '../CourseOverviewPageV2/useCourse';
import { hasAttemptInProgress } from '../CourseOverviewPageV2/ActivityCard/utils';

const CoursesInProgressPage: React.FC = () => {
    const [inProgressCoursesQuery, completedCoursesQuery] = useCoursesQueries([
        'in-progress',
        'completed',
    ]);

    const {
        activities,
        isLoading: activitiesLoading,
        isSuccess: activitiesLoaded,
    } = useActivities();

    const inProgressActivities = activities?.filter(activity =>
        hasAttemptInProgress(activity, activity.student_activity),
    );

    const hasAllDataLoaded =
        inProgressCoursesQuery.isSuccess &&
        completedCoursesQuery.isSuccess &&
        activitiesLoaded;

    const noProgress =
        !inProgressCoursesQuery.data?.length && !inProgressActivities?.length;

    return (
        <DashboardPageLayout noPadding>
            <Header heading='My progress' />
            <Box paddingY={5} paddingX={10}>
                {hasAllDataLoaded && noProgress && (
                    <Heading
                        size='md'
                        as='h3'
                        fontFamily='Roboto'
                        m='20px 0px 10px 0px'
                    >
                        My progress
                    </Heading>
                )}
                <Stack spacing={10}>
                    {hasAllDataLoaded && noProgress && (
                        <Center bg='white' borderRadius='15px' p={10}>
                            <Stack alignItems='flex-start' gap={3}>
                                <Heading size='lg'>
                                    You can find your progress here
                                </Heading>
                                <Text mt='0px!important'>
                                    Currently you do not have any progress.
                                    Please visit <b>Home</b> to get started.
                                </Text>
                                <Button
                                    as={RouterLink}
                                    to={`/${ROUTES.COURSES}`}
                                    colorScheme='brand.black'
                                    _hover={{
                                        color: 'white',
                                    }}
                                >
                                    Home
                                </Button>
                            </Stack>
                        </Center>
                    )}

                    {(activitiesLoading || !!inProgressActivities?.length) && (
                        <CarouselSectionActivities
                            heading={'Activities in progress'}
                            isLoaded={activitiesLoaded}
                            activities={inProgressActivities}
                        />
                    )}

                    {(inProgressCoursesQuery.isLoading ||
                        !!inProgressCoursesQuery.data?.length) && (
                        <CollapsibleSection
                            forceOpen={true}
                            heading={<Box mt={4}>Courses in progress</Box>}
                            courseCardVariant={'in-progress'}
                            isLoaded={inProgressCoursesQuery.isSuccess}
                            courses={inProgressCoursesQuery.data}
                        />
                    )}

                    {(!!completedCoursesQuery.data?.length ||
                        !!completedCoursesQuery.isLoading) && (
                        <CollapsibleSection
                            forceOpen={
                                inProgressCoursesQuery.isSuccess &&
                                !inProgressCoursesQuery.data.length
                            }
                            heading='Completed courses'
                            courseCardVariant={'completed'}
                            isLoaded={completedCoursesQuery.isSuccess}
                            courses={completedCoursesQuery.data}
                        />
                    )}
                </Stack>
            </Box>
        </DashboardPageLayout>
    );
};

export default CoursesInProgressPage;
