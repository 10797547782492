import * as React from 'react';
import {
    Button,
    HStack,
    Tooltip,
    useTheme,
    ButtonProps,
} from '@chakra-ui/react';
import { Icon } from '../../atoms';
import { navbarToggleStyles } from '../../theme/consts';
import {
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';

interface Props {
    isTextMode: boolean;
    setIsTextMode: (textMode: boolean) => void;
}

const InteractionModeSwitch: React.FC<Props> = ({
    isTextMode,
    setIsTextMode,
}) => {
    const { isAgentBusy, awaitingResponse } = useInteractionContext(
        InteractionContextStatus,
    );

    const theme = useTheme();

    const buttonStyles = (isActive: boolean): ButtonProps => ({
        borderRadius: '10px',
        padding: '0px 10px',
        backgroundColor: isActive ? 'white' : 'transparent',
        _hover: { backgroundColor: isActive ? 'white' : 'transparent' },
        disabled: isAgentBusy || awaitingResponse,
    });

    const iconColor = (isActive: boolean) =>
        isActive ? 'black' : theme.colors.dark['400'];

    return (
        <HStack {...navbarToggleStyles.container}>
            <Tooltip label='Switch to avatar mode' placement='bottom'>
                <Button
                    {...buttonStyles(!isTextMode)}
                    onClick={() => setIsTextMode(false)}
                >
                    <Icon
                        iconChoice='user'
                        fontAwesomeProps={{
                            size: 'lg',
                            color: iconColor(!isTextMode),
                        }}
                    />
                </Button>
            </Tooltip>
            <Tooltip label='Switch to text mode' placement='bottom'>
                <Button
                    {...buttonStyles(isTextMode)}
                    onClick={() => setIsTextMode(true)}
                >
                    <Icon
                        iconChoice='keyboard'
                        fontAwesomeProps={{
                            size: 'lg',
                            color: iconColor(isTextMode),
                        }}
                    />
                </Button>
            </Tooltip>
        </HStack>
    );
};

export default InteractionModeSwitch;
