import * as React from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@chakra-ui/react';
import { Icon } from '../../atoms';
import { IconProps } from '../../atoms/Icon/Icon';

type SpeakState = 'speak' | 'interrupt' | 'idle';

const LABELS: Record<SpeakState, string | React.ReactNode> = {
    speak: (
        <Box textAlign='center'>
            Speak now <br />
            Tap again to send
        </Box>
    ),
    interrupt: 'Interrupt',
    idle: 'Tap to speak',
};

const ICONS: Record<SpeakState, IconProps['iconChoice']> = {
    speak: 'micOn',
    interrupt: 'stop',
    idle: 'micOff',
};

interface Props {
    state: SpeakState;
    interrupt: () => void;
    toggleMic: () => void;
}

export default function TalkButton({ state, interrupt, toggleMic }: Props) {
    const theme = useTheme();

    return (
        <Tooltip label={LABELS[state]} placement='bottom' isOpen>
            <IconButton
                aria-label='Talk button'
                boxSize='75px'
                borderRadius='full'
                onClick={() => {
                    switch (state) {
                        case 'speak':
                            toggleMic();
                            break;
                        case 'interrupt':
                            interrupt();
                            break;
                        case 'idle':
                            toggleMic();
                            break;
                    }
                }}
            >
                <Icon
                    iconChoice={ICONS[state]}
                    fontAwesomeProps={{
                        size: '2x',
                        color:
                            state === 'speak'
                                ? theme.colors.green['400']
                                : 'black',
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}
