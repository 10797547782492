import { Flex, Heading, Spacer } from '@chakra-ui/react';
import React from 'react';

const Header: React.FC<{ heading: string }> = ({ heading }) => {
    return (
        <Flex
            direction='column'
            bg={'brand.gradient.cold.full'}
            textColor='white'
            w='100%'
            h='135px'
        >
            <Spacer />
            <Flex px={10} py={4}>
                <Heading fontWeight='normal'>{heading}</Heading>
            </Flex>
        </Flex>
    );
};

export default Header;
