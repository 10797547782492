import * as Sentry from '@sentry/browser';
import {
    InteractionActions,
    useInteractionAgent,
} from '../../utils/interaction/useInteractionAgent';
import {
    Executable,
    ExecutionQueue,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/utils/ExecutionQueue';

import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import {
    FPSMeterResult,
    getFps,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/utils/frameRate';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';

let rapportScene: any = null;

type Animation = {
    name: string;
    value?: boolean;
    speed?: number;
    delay?: number;
};

type RapportExecutableState = { text: string; timeoutId: NodeJS.Timeout };
const camelCaseToKebabCase = (str: string) =>
    str.replace(/([A-Z])/g, '-$1').toLowerCase();

const useRapport = (
    rapportComponentId: string,
    avatarConfig: RapportAvatarConfig,
) => {
    const { act, status } = useInteractionAgent();
    const fpsMeterRef = useRef<FPSMeterResult | null>(null);
    if (fpsMeterRef.current === null) {
        fpsMeterRef.current = getFps();
    }

    const previousMessagesInSession = useRef(0);
    const speakQueue = useRef(new ExecutionQueue(true));
    const logFps = () => {
        console.log(performance.now(), `FPS: ${fpsMeterRef.current!.fps}`);
    };
    const currentAnimation = useRef<null | string>(null);
    const currentAnimationTimeout = useRef<null | any>(null);

    useEffect(() => {
        rapportScene = document.getElementById(rapportComponentId);
        act(InteractionActions.initialise);
    }, [act, rapportComponentId]);

    const disconnect = useCallback(
        async (onDisconnect?: () => void) => {
            speakQueue.current.clear();
            await rapportScene?.sessionDisconnect();
            onDisconnect && onDisconnect();
            act(InteractionActions.disconnect);
        },
        [act],
    );

    const textToRapportExecutable = useCallback(
        (
            text: string,
            traceId: string,
            timeout?: number,
            onTimeout?: () => void,
            onEnd?: () => void,
            entryAnimation?: Animation,
            exitAnimation?: Animation,
        ): Executable<RapportExecutableState> => {
            previousMessagesInSession.current += 1;
            return new Executable<RapportExecutableState>({
                onSchedule: () => {
                    rapportScene?.modules?.tts.sendText(text);
                    logFps();
                },
                onAvailable: state => {
                    const callback = () => {
                        Sentry.withScope(scope => {
                            scope.setContext('extra', {
                                traceId,
                                previousMessagesInSession,
                            });
                            Sentry.captureMessage(
                                'Rapport Audio Stream did not process in time (ttsStart not fired)',
                            );
                        });
                        onTimeout && onTimeout();
                    };
                    state.text = text;

                    if (timeout) {
                        state.timeoutId = setTimeout(callback, timeout * 1000);
                    }
                    logFps();
                },
                onStarted: state => {
                    if (
                        entryAnimation &&
                        entryAnimation.value &&
                        currentAnimation.current === null
                    ) {
                        rapportScene?.animations.play(
                            entryAnimation.name,
                            false,
                            entryAnimation?.speed,
                        );
                        currentAnimation.current = entryAnimation.name;
                    }
                    if (
                        entryAnimation &&
                        !entryAnimation.value &&
                        currentAnimation.current === entryAnimation.name
                    ) {
                        clearTimeout(currentAnimationTimeout.current);
                        currentAnimationTimeout.current = null;
                        rapportScene?.animations.play(
                            entryAnimation.name,
                            false,
                            entryAnimation?.speed !== undefined
                                ? -1 * entryAnimation.speed
                                : undefined,
                        );
                        currentAnimation.current = null;
                    }
                    clearTimeout(state.timeoutId);
                    logFps();
                },
                onFinished: state => {
                    if (
                        exitAnimation &&
                        exitAnimation.name === currentAnimation.current &&
                        currentAnimationTimeout.current === null
                    ) {
                        if (exitAnimation.delay !== undefined) {
                            currentAnimationTimeout.current = setTimeout(() => {
                                rapportScene?.animations.play(
                                    exitAnimation.name,
                                    false,
                                    exitAnimation.speed !== undefined
                                        ? -1 * exitAnimation.speed
                                        : -1,
                                );
                                currentAnimation.current = null;
                                currentAnimationTimeout.current = null;
                            }, exitAnimation.delay * 1000);
                        } else {
                            rapportScene?.animations.play(
                                exitAnimation.name,
                                false,
                                exitAnimation.speed !== undefined
                                    ? -1 * exitAnimation.speed
                                    : -1,
                            );
                            currentAnimation.current = null;
                        }
                    }
                    clearTimeout(state.timeoutId);
                    onEnd && onEnd();
                },
            });
        },
        [],
    );

    const initialiseRapport = useCallback(
        (
            onSessionConnected: () => void,
            onSessionDisconnected: (err: any) => void,
            onMessageRecognised: (text: string, languageCode: string) => void,

            configOverrides?: {
                micRequired?: boolean;
                micMuted?: boolean;
                speakerMuted?: boolean;
            },
        ): void => {
            act(InteractionActions.connect);

            const { animationController, lights } = avatarConfig;
            rapportScene.style.visibility = 'hidden';

            const {
                mood: baseMood = 'neutral',
                scale: baseMoodScale = 1,
            } = animationController.base!;

            act(InteractionActions.connect);

            rapportScene
                .sessionRequest({
                    sessionConnected: () => {
                        act(InteractionActions.connect_success);
                        onSessionConnected();

                        rapportScene.modules.ac.setMood(baseMood);
                        rapportScene.modules.ac.setScale(baseMoodScale);

                        if (lights) {
                            rapportScene.lights.delete();
                            lights.forEach(light =>
                                rapportScene.lights.create(light),
                            );
                        }
                        rapportScene.style.visibility = 'visible';
                    },
                    sessionDisconnected: (err: any) => {
                        speakQueue.current.clear();
                        onSessionDisconnected(err);
                    },
                    ttsOff: () => {
                        console.log(performance.now(), 'TTS Off');
                    },
                    ttsOn: () => {
                        console.log(performance.now(), 'TTS On');
                    },
                    ttsStart: e => {
                        speakQueue.current.startAvailable();
                        speakQueue.current.scheduleNext();
                    },
                    ttsEnd: () => {
                        speakQueue.current.finishRunningTask();
                        if (speakQueue.current.size() === 0) {
                            rapportScene?.modules?.ac.setMood(baseMood);
                            rapportScene?.modules?.ac.setScale(baseMoodScale);
                        } else {
                            speakQueue.current.makeAvailable();
                        }
                    },
                    ttsMessage: event => {
                        console.log(
                            performance.now(),
                            '[RAPPORT TTS] ttsMessage',
                        );
                        console.debug(event);
                    },
                    asrMessage: ev => {
                        console.debug('[RAPPORT ASR]', ev.params);
                        if (ev.params.text) {
                            console.log('[RAPPORT ASR] Recognised text');
                            onMessageRecognised(ev.params.text, ev.params.lang);
                        }
                    },
                    error: e => {
                        Sentry.captureException(e);
                        console.error(e);
                    },
                    warning: e => {
                        Sentry.captureEvent(e);
                        console.warn(e);
                    },
                    info: e => {
                        Sentry.captureEvent(e);
                        console.info(e);
                    },
                    orbitalControls: false,
                    // This y value is used to coordinate with the camera configurations from Blender
                    // as camera rotation does not get read properly from Blender we have to manually set it here
                    // 1.534m is the "camera constraint" from blender (and should be the height of the avatar's eyes)
                    cameraLookAt: { x: 0, y: 1.534, z: 0 },
                    showLogo: false,
                    ...configOverrides,
                })
                .catch(err => {
                    console.error(performance.now(), err);
                    if (err.code !== 'RAPPORT_NO_MIC_PERMISSION') {
                        act(InteractionActions.connect_failed);
                    }
                });
        },
        [act, avatarConfig],
    );

    const speak = useCallback(
        (
            textual?: string,
            ssml?: string,
            traceId?: string,
            timeout?: number,
            onTimeout?: () => void,
            onEnd?: () => void,
            entryAnimation?: Animation,
            exitAnimation?: Animation,
        ) => {
            if (rapportScene.modules === null) return;

            let speakText: null | string[] = null;
            if (ssml) {
                speakText = [ssml.trim()];
            } else if (textual) {
                // TTS takes some time to generate longer text, so we break it down into chunks
                // each chunk is generated as soon as the current one starts being played, to preserve order
                // otherwise the first one to be syntesized will be played (i.e. the shortest sentance)
                const segmenter = new Intl.Segmenter('en', {
                    granularity: 'sentence',
                });
                speakText = Array.from(
                    segmenter.segment(textual),
                    s => s.segment,
                );
            }

            if (!speakText || speakText.length === 0) return;

            console.debug(`[DEBUG]: instruction to speak text: ${textual}`, {
                speakText,
            });

            const speakExecutables = speakText.map((text, index) =>
                textToRapportExecutable(
                    text,
                    `id-${traceId}-sentenceIdx-${index}`,
                    timeout,
                    onTimeout,
                    onEnd,
                    index === 0 ? entryAnimation : undefined,
                    index === speakText!.length - 1 ? exitAnimation : undefined,
                ),
            );
            speakQueue.current.push(...speakExecutables);

            const { talking } = avatarConfig.animationController;
            if (talking?.scale) {
                rapportScene.modules.ac.setScale(talking.scale);
            }
            if (talking?.mood) {
                rapportScene.modules.ac.setMood(talking.mood);
            }
        },
        [avatarConfig.animationController, textToRapportExecutable],
    );

    const speakProcessor = useCallback(
        (action, activeStage, timeout, onTimeout) => {
            const { textual, auditory, behavioural } = action.payload;
            if (!textual?.text && !auditory.ssml) return;

            const { promise, resolve } = promiseWithResolvers();
            speak(
                textual?.text,
                auditory.ssml,
                action.id.toString(),
                timeout,
                onTimeout,
                resolve,
                behavioural.entry_states[0],
                behavioural.exit_states[0],
            );

            return {
                promise,
                stop: () => {
                    // clear any pending executables from the speak queue
                    // and stop rapport from speaking the current message as well
                    speakQueue.current.clear();
                    rapportScene.modules.commands.stopAllSpeech();
                },
            };
        },
        [speak],
    );

    const toggleAvatarAudio = useCallback((on: boolean) => {
        rapportScene?.muteSpeaker(!on);
    }, []);

    const listen = useCallback((shouldListen: boolean) => {
        rapportScene?.muteMic(!shouldListen);
    }, []);

    const applyUPXSVideoStyling = useCallback((style: CSSProperties) => {
        const video = rapportScene.shadowRoot.querySelector('video');
        if (video) {
            Object.entries(style).forEach(([key, value]) => {
                const propName = camelCaseToKebabCase(key);
                video.style[propName] = value;
            });
        }
    }, []);

    return {
        status,
        initialiseRapport,
        toggleAvatarAudio,
        applyUPXSVideoStyling,
        speakProcessor,
        listen,
        disconnect,
    };
};

export default useRapport;
