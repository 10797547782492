import React, { Fragment, useState } from 'react';
import { TrialData } from '../contexts/StudentFeaturesContext';
import { useLocation } from 'react-router-dom';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import UserFeedbackModal from './UserFeedbackModal';

import VerticalGradient from '../../images/gradient-vertical.png';
import { authService } from '../../services';
import { TUTOR_AVATAR_BRAND } from '../../consts/images';

const TrialExpiredModal: React.FC<{ trialData: TrialData }> = ({
    trialData,
}) => {
    const [modal, setModal] = useState<'trial-expired' | 'feedback' | null>(
        () => (trialData.expired ? 'trial-expired' : null),
    );
    const location = useLocation();

    return (
        /* Re-rendering on location change prevents users from hiding it with CSS manipulation and keep using the platform */
        <Fragment key={location.pathname}>
            <Modal
                show={modal === 'trial-expired'}
                backdrop='static'
                size='xl'
                contentClassName='border-0 overflow-hidden'
            >
                <Modal.Body className='p-0'>
                    <Row noGutters>
                        <Col
                            xs={6}
                            className='d-none d-lg-flex flex-column align-items-center'
                            style={{
                                backgroundImage: `url(${VerticalGradient})`,
                                backgroundPosition: 'center bottom',
                                backgroundSize: 'cover',
                            }}
                        >
                            <h3 className='h3 text-white text-center p-3 my-4 my-xl-5'>
                                Your trial access has now ended
                            </h3>
                            <img width='85%' src={TUTOR_AVATAR_BRAND} alt='' />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-center p-4'>
                            <h3 className='h3 mb-4 text-center'>
                                Thank you for taking part in our trial.
                            </h3>
                            <h3 className='h3 text-center'>
                                We&apos;d love your feedback.
                            </h3>
                            <Button
                                size='lg'
                                className='mt-3 mb-1'
                                onClick={() => setModal('feedback')}
                            >
                                Leave us feedback
                            </Button>
                            <Button
                                className='mt-2'
                                variant='link'
                                onClick={() => authService.logout()}
                            >
                                Logout
                            </Button>
                            <div />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <UserFeedbackModal
                show={modal === 'feedback'}
                onClose={() => setModal('trial-expired')}
            />
        </Fragment>
    );
};

export default TrialExpiredModal;
