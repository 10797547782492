import { BoxProps } from '@chakra-ui/react';
import { theme } from './theme';

export const scrollBarStyles = {
    '::-webkit-scrollbar': {
        width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'gray.600',
        borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: 'gray.600',
    },
    '::-webkit-scrollbar-track': {
        background: 'gray.200',
        borderRadius: '8px',
    },
};

export const navbarToggleStyles = {
    container: {
        backgroundColor: 'dark.700',
        border: '1px solid',
        borderColor: 'dark.500',
        borderRadius: '10px',
        padding: '5px',
        h: '3.2rem',
    },
};

export const checkBoxStyles = {
    m: '0px',
    spacing: '20px',
    w: '48%',
    h: '50px',
    pl: '20px',
    borderRadius: 'full',
    _hover: {
        backgroundColor: 'dark.600',
        transition: 'all 500ms',
    },
    _checked: {
        backgroundColor: 'white',
        color: 'black',
        transition: 'all 500ms',
    },
    "span[class*='checkbox__control']:not([data-disabled])": {
        width: '30px',
        height: '30px',
        border: '2px solid',
        borderColor: 'dark.500',
        borderRadius: 'full',
        boxShadow: 'none',
        _checked: {
            bg: 'black',
        },
    },
};

export const getChatStyles = (isTextMode: boolean) => {
    const chatStyles = {
        textMode: {
            chatContainer: {
                h: '100%',
                paddingBottom: '10px',
            },
            historyContainer: {
                mx: 'auto',
                w: ['90%', '70%', '70%', '60%', '50%', '45%'],
            },
            avatarMessage: {
                backgroundColor: 'transparent',
                p: '0px 20px 0px 0px',
                // To center the first line with the avatar circle on the left
                // 2.25rem = font-size (1rem) * line-height (1.5)
                // 32px    = height of the avatar circle
                mt: 'calc(2.25rem - 32px)',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['500'],
            },
        },
        avatar: {
            chatContainer: {
                minH: 0,
                flex: 1,
            },
            historyContainer: {
                mx: 'auto',
                w: '90%',
            },
            avatarMessage: {
                backgroundColor: theme.colors.dark['500'],
                p: '10px',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['450'],
            },
        },
    };

    return isTextMode ? chatStyles.textMode : chatStyles.avatar;
};

export const ACTIVITY_CARD_HEIGHT = '410px';

export const MOBILE_MENU_HEIGHT = '50px';

export const medalStyles: Record<
    number,
    Pick<BoxProps, 'bg' | 'borderColor' | 'color' | 'opacity'>
> = {
    1: {
        bg: '#FDE4BC',
        borderColor: '#ECA780',
        color: '#ECA780',
    },
    2: {
        bg: '#E0E6ED',
        borderColor: '#9399A4',
        color: '#9399A4',
    },
    3: {
        bg: '#E8C587',
        borderColor: '#C58F7C',
        color: '#C58F7C',
    },
};

export const leaderboardEntryHighlightStyles: Record<string, BoxProps> = {
    dark: {
        bg: 'black',
        color: 'white',
    },
    light: {
        fontWeight: 'bold',
        bg: 'white',
    },
    none: {
        bg: 'white',
    },
};
