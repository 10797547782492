import * as React from 'react';
import { Fragment, useRef } from 'react';
import {
    Box,
    Flex,
    Spacer,
    useBreakpointValue,
    VStack,
} from '@chakra-ui/react';
import AvatarInfo from '../../molecules/AvatarInfo/AvatarInfo';
import ChatHistory, {
    AttachmentsDisplay,
} from '../../molecules/ChatHistory/ChatHistory';
import MessageInput from '../../organisms/MessageInput/MessageInput';
import {
    InteractionContextAPI,
    InteractionContextData,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { getChatStyles, scrollBarStyles } from '../../theme/consts';
import Hint from '../../atoms/Hint/Hint';
import { isDesktop } from 'react-device-detect';
import { useStoreWithArray } from '../../stores';
import useChatScroll from '../../hooks/useChatScroll';

export enum UserMessageSource {
    TEXT = 'text',
    DIALOGUE_CHOICE = 'dialogue_choice',
    BROWSER_RECOGNISED_SPEECH = 'browser_recognised_speech',
    CLOUD_RECOGNISED_SPEECH = 'cloud_recognised_speech',
    HINT = 'hint',
}

export interface Props {
    title: string;
    showChatHistory?: boolean;
    onCitationClick: (citation: Citation) => void;
}

const Chat = ({ title, showChatHistory = true, onCitationClick }: Props) => {
    const { messages, hints } = useInteractionContext(InteractionContextData);
    const { sendMessage, activeStage } = useInteractionContext(
        InteractionContextAPI,
    );
    const { awaitingResponse, isDisabled } = useInteractionContext(
        InteractionContextStatus,
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const innerContainerRef = useRef<HTMLDivElement>(null);

    const isLargeDesktop = useBreakpointValue({ '2xl': true });

    const { isTextMode, isMicOn } = useStoreWithArray([
        'isTextMode',
        'isMicOn',
    ]);
    const styles = getChatStyles(isTextMode);

    const avatarImageSrc = activeStage.interaction_stage.avatar_img || '';

    // Limit the number of hints when the space is limited so that there is no overflow on desktops
    const displayHints = hints.slice(
        0,
        isDesktop && (!isTextMode || !isLargeDesktop) ? 3 : 4,
    );

    const hasMessages = messages.length > 0;

    useChatScroll(messages, containerRef, innerContainerRef, showChatHistory);

    return (
        <Flex direction='column' {...styles.chatContainer}>
            {!hasMessages && (
                <AvatarInfo
                    imageSrc={avatarImageSrc}
                    title={title}
                    textColor={isTextMode ? 'whiteAlpha.900' : 'blackAlpha.900'}
                />
            )}
            {hasMessages &&
                (showChatHistory ? (
                    <Fragment>
                        <Spacer />
                        <Box
                            overflowY='auto'
                            ref={containerRef}
                            sx={scrollBarStyles}
                        >
                            {showChatHistory && (
                                <ChatHistory
                                    messages={messages}
                                    avatarImageSrc={avatarImageSrc}
                                    isTextMode={isTextMode}
                                    onCitationClick={onCitationClick}
                                    innerContainerRef={innerContainerRef}
                                />
                            )}
                        </Box>
                    </Fragment>
                ) : (
                    <Flex flex={1} overflowY='auto' sx={scrollBarStyles}>
                        <AttachmentsDisplay
                            messages={messages}
                            isTextMode={isTextMode}
                        />
                    </Flex>
                ))}
            <VStack {...styles.historyContainer}>
                <Flex w='100%' mt='25px' overflowX='auto'>
                    {displayHints.map((hint, i) => (
                        <Hint
                            key={hint}
                            // overflow on justify-content: center has odd behaviour when the content overflows (and 'safe' is not supported everywhere)
                            // so we use auto margins on the first and last children
                            // https://bhch.github.io/posts/2021/04/centring-flex-items-and-allowing-overflow-scroll/
                            ml={i === 0 ? 'auto' : '0.5rem'}
                            mr={
                                i === displayHints.length - 1
                                    ? 'auto'
                                    : undefined
                            }
                            disabled={awaitingResponse || isDisabled || isMicOn}
                            onClick={() => {
                                sendMessage(hint, {
                                    message_source: UserMessageSource.HINT,
                                });
                            }}
                        >
                            {hint}
                        </Hint>
                    ))}
                </Flex>
                <MessageInput
                    onSubmit={message => {
                        sendMessage(message, {
                            message_source: UserMessageSource.TEXT,
                        });
                    }}
                />
            </VStack>
        </Flex>
    );
};

export default Chat;
