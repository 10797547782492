import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Center, Flex, FlexProps, Link } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';

interface NavItemProps {
    icon?: IconDefinition;
    isActive?: boolean;
    route: string;
    fontSize?: FlexProps['fontSize'];
}

const NavItem: React.FC<NavItemProps> = props => (
    <Link
        as={RouterLink}
        to={props.route}
        fontWeight='semibold'
        textDecoration='none'
        color={props.isActive ? 'white' : 'brand.black.400'}
        _hover={{
            color: 'white',
        }}
        _active={{
            color: 'white',
        }}
        _focus={{
            color: 'white',
        }}
    >
        <Flex
            role='group'
            alignItems='center'
            minHeight='30px'
            fontSize={props.fontSize ? props.fontSize : undefined}
        >
            {props.icon && (
                <Center w='30px' mr={props.children ? 2 : 0}>
                    <FontAwesomeIcon size='lg' icon={props.icon} />
                </Center>
            )}
            {props.children}
        </Flex>
    </Link>
);

export default NavItem;
