import * as React from 'react';
import { Fragment } from 'react';
import { Box, Center, Flex, Grid, GridItem } from '@chakra-ui/react';
import ControlToggle from '../../molecules/ControlToggle/ControlToggle';
import TalkButton from '../../molecules/TalkButton/TalkButton';
import { useStoreWithArray } from '../../stores/';
import {
    InteractionContextAPI,
    InteractionContextData,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { getLastAgentMessage } from '../../utils/interaction/utils';
import { Props as ChatProps } from '../../organisms/Chat/Chat';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import Captions from '../../atoms/Captions/Captions';

export const getCaptionMessage = (
    isMicOn: boolean,
    awaitingResponse: boolean,
    isTranscribing: boolean,
) => {
    if (isMicOn) return 'Listening...';
    if (isTranscribing) return 'Transcribing...';
    if (awaitingResponse) return 'Thinking...';
    else return '';
};

export default function AvatarMobileOverlayTemplate({
    onCitationClick,
}: ChatProps) {
    const {
        isCaptionsOn,
        isAudioOn,
        toggleAudio,
        isMicOn,
        toggleMic,
    } = useStoreWithArray([
        'isCaptionsOn',
        'isAudioOn',
        'toggleAudio',
        'isMicOn',
        'toggleMic',
    ]);

    const { messages } = useInteractionContext(InteractionContextData);
    const lastAgentMessage = getLastAgentMessage(messages);

    const { interrupt } = useInteractionContext(InteractionContextAPI);
    const {
        awaitingResponse,
        isAgentBusy,
        isTranscribing,
    } = useInteractionContext(InteractionContextStatus);

    const captionMessage = getCaptionMessage(
        isMicOn,
        awaitingResponse,
        isTranscribing,
    );

    return (
        <Fragment>
            <Grid
                h='100%'
                w='100%'
                templateColumns='repeat(3, 1fr)'
                templateRows='repeat(7, 1fr)'
                position='fixed'
                bottom='0px'
                pb='20px'
                zIndex={10}
            >
                <GridItem colSpan={3} rowSpan={5} /> {/* Empty slot */}
                <GridItem
                    colSpan={3}
                    minH={captionMessage ? '110px' : undefined}
                >
                    <Flex h='100%' justifyContent='center'>
                        <Box
                            w={
                                awaitingResponse
                                    ? 'fit-content'
                                    : ['90%', '60%', '60%', '50%']
                            }
                        >
                            {isCaptionsOn &&
                                lastAgentMessage &&
                                !captionMessage && (
                                    <AvatarMessage
                                        text={
                                            lastAgentMessage.text as AvatarText
                                        }
                                        isTextMode={false}
                                        onCitationClick={onCitationClick}
                                        actionId={lastAgentMessage.actionId}
                                        showFeedbackButtons={true}
                                    />
                                )}
                            {captionMessage && (
                                <Captions captions={captionMessage} />
                            )}
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem /> {/* Empty slot */}
                <GridItem>
                    <Center h='100%' alignItems='flex-end'>
                        <TalkButton
                            state={
                                isMicOn
                                    ? 'speak'
                                    : isAgentBusy
                                    ? 'interrupt'
                                    : 'idle'
                            }
                            toggleMic={toggleMic}
                            interrupt={interrupt}
                        />
                    </Center>
                </GridItem>
                <GridItem>
                    <Center h='100%' alignItems='flex-end'>
                        <ControlToggle
                            icon={isAudioOn ? 'audioOn' : 'audioOff'}
                            ariaLabel='Audio toggle button'
                            tooltipLabel={
                                isAudioOn ? 'Turn audio off' : 'Turn audio on'
                            }
                            onClick={() => toggleAudio()}
                        />
                    </Center>
                </GridItem>
            </Grid>
        </Fragment>
    );
}
