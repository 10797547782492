import React from 'react';

const useStateWithDelay = <T,>(
    initialValue: T,
): [T, (newVal: T) => void, (newVal: T, delay: number) => void] => {
    const [state, setState] = React.useState<T>(initialValue);
    const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const setStateWithDelay = React.useCallback(
        (newValue: T, delay: number) => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => setState(newValue), delay);
        },
        [],
    );

    return [state, setState, setStateWithDelay];
};

export default useStateWithDelay;
