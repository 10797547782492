import { InteractionActions } from './useInteractionAgent';
import { APIError } from '../../../../core/src/services/api.service';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import ExerciseAPI from '../../../../../apps/mooc-frontend/src/components/activities/ExerciseAPI';
import { interactionsApi } from '../../../../../apps/mooc-frontend/src/services';

export const ChatWebsocketCodes = {
    CLOSE_NORMAL: 1000,
    NOT_FOUND: 4004,
    STAGE_NOT_ACTIVE: 4050,
};

export const ChatWebsocketCloseReason = {
    CLIENT_RECONNECT: 'client-reconnect',
};

class HTTPToWebsocketAdapter {
    onclose: ((this: HTTPToWebsocketAdapter) => any) | null = null;
    onerror:
        | ((this: HTTPToWebsocketAdapter, ev: APIError | any) => any)
        | null = null;
    onmessage: ((this: HTTPToWebsocketAdapter, data: any) => any) | null = null;
    onopen: ((this: HTTPToWebsocketAdapter) => any) | null = null;
    // Provide the same interface as a WS connection,
    private exerciseAPI: ExerciseAPI;
    private url: string;

    constructor(exerciseAPI: ExerciseAPI, url: string) {
        this.exerciseAPI = exerciseAPI;
        this.url = `${url}send/`;
    }

    public send(data: any) {
        if (typeof data === 'string') {
            data = JSON.parse(data);
        }
        const queryParams = new URLSearchParams();
        if (data['skip_tts_synthesis'] !== undefined) {
            queryParams.set('skip_tts_synthesis', data['skip_tts_synthesis']);
        }
        this.exerciseAPI
            .post(`${this.url}?${queryParams.toString()}`, data)
            .then(data => {
                if (this.onmessage) {
                    this.onmessage(data);
                }
            })
            .catch(error => {
                if (this.onerror) {
                    this.onerror(error);
                }
            });
    }

    public close(code?: number, reason?: string) {
        return;
    }
}

export interface ChatConnOptions {
    chatUrl: string;
    activeStage: ActiveStage;
    sessionData: any;
    act: any;
    exerciseAPI: ExerciseAPI;
    avatarRef?: any;
    setActions: (actions: AgentAction[]) => void;
    skipAction?: (actions: AgentAction) => boolean;
    onSuccess: (connection: ChatConnection) => void;
    shouldReconnect?: () => boolean;
    onError: (errorMsg: string) => void;
}

export const openChatHttpConn = ({
    chatUrl,
    activeStage,
    sessionData,
    act,
    exerciseAPI,
    avatarRef,
    setActions,
    skipAction,
    onSuccess,
    onError,
}: ChatConnOptions) => {
    act(InteractionActions.connect);
    const httpConn = new HTTPToWebsocketAdapter(exerciseAPI, chatUrl);

    httpConn.onopen = () => {
        act(InteractionActions.connect_success);
        onSuccess(httpConn);

        // Old UI
        if (avatarRef) {
            act(InteractionActions.send);
            httpConn.send({
                action_type:
                    activeStage!.entries?.length > 0 ? 'resume' : 'start',
                payload: {},
                skip_tts_synthesis:
                    activeStage!.interaction_stage.avatar_config?.type ===
                    'rapport'
                        ? avatarRef.current?.isAvatarEnabled().toString()
                        : undefined,
            });
        }
    };

    httpConn.onmessage = (data: {
        actions: AgentAction[];
        error_message?: string;
    }) => {
        const actions = skipAction
            ? data.actions.filter(action => !skipAction(action))
            : data.actions;

        if (actions.length === 0 && data.actions.length > 0) {
            return;
        }

        const nonManagementActions = actions
            .filter(a => a.action_type !== 'management_command')
            .filter(a => !!a.payload);

        act(InteractionActions.receive);
        if (nonManagementActions.length) {
            setActions(nonManagementActions);
        } else {
            act(InteractionActions.processed);
        }
    };

    httpConn.onerror = error => {
        if (
            error instanceof APIError &&
            error.responseBody &&
            'error_message' in error.responseBody &&
            error.responseBody['error_message']
        ) {
            onError(error.responseBody['error_message']);
        } else {
            onError(error.toString());
        }
    };
    httpConn.onopen();
};

export const openChatWebsocketConn = async (
    connOptions: ChatConnOptions,
    retryNumber = 0,
) => {
    const {
        chatUrl,
        activeStage,
        sessionData,
        act,
        exerciseAPI,
        avatarRef,
        setActions,
        skipAction,
        shouldReconnect,
        onError,
        onSuccess,
    } = connOptions;

    act(InteractionActions.connect);
    const url = new URL(exerciseAPI.absoluteUrl(chatUrl));
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    url.pathname = `/ws${url.pathname}chat/`;

    const { token } = await interactionsApi.post('ws-token/login/');
    url.searchParams.set('token', token);

    const wsConn = new WebSocket(url.href);

    wsConn.onopen = event => {
        act(InteractionActions.connect_success);
        onSuccess(wsConn);

        if (retryNumber === 0 && avatarRef) {
            act(InteractionActions.send);
            // Awaiting response is set to false when receiving the response to start/resume
            wsConn.send(
                JSON.stringify({
                    action_type:
                        activeStage!.entries?.length > 0 ? 'resume' : 'start',
                    payload: {},
                    skip_tts_synthesis:
                        activeStage!.interaction_stage.avatar_config?.type ===
                        'rapport'
                            ? avatarRef.current?.isAvatarEnabled().toString()
                            : undefined,
                }),
            );
        }
        // Reset retry number on successful connection
        retryNumber = 0;
    };

    type ChatWSError = { error: string; details: string };
    type ChatWSMessage =
        | { actions: AgentAction[]; partial?: boolean }
        | ChatWSError;
    function isError(message: ChatWSMessage): message is ChatWSError {
        return (message as ChatWSError).error !== undefined;
    }

    wsConn.onmessage = (message: MessageEvent<string>) => {
        const data = JSON.parse(message.data) as ChatWSMessage;
        if (isError(data)) {
            onError(data.details);
        } else {
            const actions =
                skipAction !== undefined
                    ? data.actions.filter(action => !skipAction(action))
                    : data.actions;

            // Hypothetical edge case when there's no action in the response
            // we still want to mark it as received
            if (actions.length === 0 && data.actions.length > 0) {
                return;
            }

            const isManagementCommand = (a: AgentAction): boolean =>
                a.action_type === 'management_command';

            const nonManagementActions = data.actions
                .filter(a => !isManagementCommand(a))
                .map(a => ({ ...a, partial: data.partial }));

            act(InteractionActions.receive);
            if (nonManagementActions.length) {
                setActions(nonManagementActions);
            } else {
                act(InteractionActions.processed);
            }
        }
    };

    wsConn.onclose = (closeEvent: CloseEvent) => {
        if (closeEvent.reason === ChatWebsocketCloseReason.CLIENT_RECONNECT)
            return;

        act(InteractionActions.disconnect);
        if (closeEvent.code !== ChatWebsocketCodes.CLOSE_NORMAL) {
            switch (closeEvent.code) {
                case ChatWebsocketCodes.STAGE_NOT_ACTIVE:
                    onError(
                        'This interaction stage has been already completed or canceled.',
                    );
                    break;
                // case ChatWebsocketCodes.NOT_FOUND:
                // The NOT_FOUND case is impossible to reach since there are setup API calls before
                // the ws connection
                default:
                    if (shouldReconnect === undefined || shouldReconnect()) {
                        if (retryNumber >= 5) {
                            const logFormattedEvent = {
                                type: closeEvent.type,
                                code: closeEvent.code,
                                reason: closeEvent.reason,
                                is_trusted: closeEvent.isTrusted,
                                serialized: JSON.stringify(closeEvent),
                            };
                            const msg =
                                'Failed to open WS connection - falling back to HTTP';

                            console.log(msg);
                            console.log(logFormattedEvent);

                            Sentry.withScope(scope => {
                                Sentry.setContext('session_data', {
                                    url: url.href,
                                    interaction_id:
                                        activeStage?.interaction_stage.id,
                                    session_id: sessionData?.id,
                                });
                                Sentry.setContext(
                                    'closeEvent',
                                    logFormattedEvent,
                                );
                                Sentry.captureMessage(msg, Severity.Warning);
                            });

                            openChatHttpConn(connOptions);
                        } else {
                            setTimeout(
                                () =>
                                    openChatWebsocketConn(
                                        connOptions,
                                        retryNumber + 1,
                                    ),
                                500,
                            );
                        }
                    }
            }
        }
    };

    wsConn.onerror = function(err) {
        act(InteractionActions.disconnect);
        console.error('Socket encountered error: ', err, 'Closing socket');
        wsConn.close();
    };
};

export type ChatConnection = WebSocket | HTTPToWebsocketAdapter;
