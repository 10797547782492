import { useEffect } from 'react';

interface Props {
    keyName: string;
    onPush: () => void;
    onRelease: () => void;
    preventDefault?: boolean;
}

const isInputElementActive = () =>
    ['input', 'textarea', 'select'].includes(
        document.activeElement?.tagName.toLowerCase() || '',
    );

const isInputElementEmpty = () => {
    if (
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement
    ) {
        return !document.activeElement.value;
    }
};

const isTargetKey = (keyboardEvent: KeyboardEvent, key: string) => {
    if (key === 'space') {
        if (isInputElementActive() && !isInputElementEmpty()) return false;
        return keyboardEvent.key === key || keyboardEvent.code === 'Space';
    } else {
        if (isInputElementActive()) return false;
        return keyboardEvent.key === key;
    }
};

const KeyControl = ({
    keyName,
    onPush,
    onRelease,
    preventDefault = false,
}: Props) => {
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (!isTargetKey(event, keyName)) return;
            if (event.repeat) return;
            onPush && onPush();
        };

        const onKeyUp = (event: KeyboardEvent) => {
            if (!isTargetKey(event, keyName)) return;
            if (preventDefault) event.preventDefault();
            onRelease && onRelease();
        };

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [keyName, onPush, onRelease, preventDefault]);

    return null;
};

export default KeyControl;
