import React from 'react';
import { InteractionStageFeedback } from '../types';
import { Box, Heading, Text, Accordion } from '@chakra-ui/react';
import { LLMStage } from './types';
import FeedbackOverview from './FeedbackOverview';
import CollapsibleFeedback from './CollapsibleFeedback';

export const LLMFeedback: React.FC<InteractionStageFeedback<LLMStage>> = ({
    marking_feedback,
}) => {
    if (!marking_feedback) return null;

    return (
        <Box>
            <FeedbackOverview
                available_points={marking_feedback.feedback.available_points}
                generated_feedback={
                    marking_feedback.feedback.generated_feedback
                }
                score={marking_feedback.feedback.score}
            />
            {marking_feedback.sections?.map(
                ({ title, description, feedback }, i) => (
                    <Box key={title}>
                        <Heading as='h3' size='lg'>
                            {title}
                            {!!feedback.available_points &&
                                ` (${feedback.awarded_points} / ${feedback.available_points})`}
                        </Heading>
                        <Text mb={2} whiteSpace='pre-line'>
                            {description}
                        </Text>
                        <Text whiteSpace='pre-line'>
                            {feedback.generated_feedback}
                        </Text>
                    </Box>
                ),
            )}
        </Box>
    );
};

export const LLMCollapsibleFeedback: React.FC<InteractionStageFeedback<
    LLMStage
>> = ({ marking_feedback }) => {
    if (!marking_feedback) return null;

    return (
        <Box>
            <FeedbackOverview
                available_points={marking_feedback.feedback.available_points}
                generated_feedback={
                    marking_feedback.feedback.generated_feedback
                }
                score={marking_feedback.feedback.score}
            />
            <Accordion defaultIndex={[0]} allowMultiple>
                {marking_feedback.sections?.map(section => (
                    <CollapsibleFeedback
                        key={section.title}
                        {...section}
                        accordionBtnFontSize='1.75rem'
                    />
                ))}
            </Accordion>
        </Box>
    );
};

export const LLMNestedCollapsibleFeedback = LLMCollapsibleFeedback;
