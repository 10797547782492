import React, {
    CSSProperties,
    forwardRef,
    Fragment,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { SimliClient, SimliClientConfig } from 'simli-client';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { Box, Center } from '@chakra-ui/react';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import GreenScreenVideo from './GreenScreenVideo';
import LoadingSpinner from '../../../../../apps/mooc-frontend/src/components/generic/LoadingSpinner';
import { useStoreWithArray, useStore } from '../../stores';
import { isMobile, useMobileOrientation } from 'react-device-detect';

type SimliEvent = 'connected' | 'disconnected' | 'failed';
const SIMLI_EVENTS = ['connected', 'disconnected', 'failed'] as SimliEvent[];

export interface Props {
    ref: React.Ref<any>;
    simliConfig: Omit<SimliClientConfig, 'audioRef' | 'videoRef' | 'SimliURL'>;
    eventHandler: (event: SimliEvent, simliClient: SimliClient) => void;
    backgroundSrc?: string;
    shouldUseGreenScreenRemoval?: boolean;
}

const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';

async function convertMp3ToPCM16(mp3Url: string) {
    const start = performance.now();

    // Fetch the MP3 file
    const response = await fetch(mp3Url);
    const arrayBuffer = await response.arrayBuffer();

    // Create audio context
    const audioContext = new (window.AudioContext ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.webkitAudioContext)();

    // Decode the audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Create an offline context for resampling
    const offlineContext = new OfflineAudioContext(
        1,
        audioBuffer.duration * 16000,
        16000,
    );

    // Create a buffer source
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;

    // Connect the source to the offline context destination
    source.connect(offlineContext.destination);

    // Start the source and render
    source.start(0);
    const renderedBuffer = await offlineContext.startRendering();

    // Get the PCM data as a Float32Array
    const channelData = renderedBuffer.getChannelData(0);

    // Convert Float32Array to Int16Array (PCM16)
    const pcm16 = new Int16Array(channelData.length);
    for (let i = 0; i < channelData.length; i++) {
        const s = Math.max(-1, Math.min(1, channelData[i]));
        pcm16[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }

    // Convert Int16Array to Uint16Array
    const uint8Array = new Uint8Array(pcm16.buffer);

    console.log('convertMp3ToPCM16: ', performance.now() - start);
    return uint8Array;
}

const simliClient = new SimliClient();

export interface AvatarWrapperSimliRef {
    start: () => void;
    simliClient: SimliClient;
}

const AvatarWrapperSimli: React.FC<Props> = forwardRef<
    AvatarWrapperSimliRef,
    Props
>(
    (
        {
            simliConfig,
            backgroundSrc,
            eventHandler,
            shouldUseGreenScreenRemoval,
        },
        ref,
    ) => {
        const videoRef = useRef<HTMLVideoElement>(null);
        const audioRef = useRef<HTMLAudioElement>(null);
        const { isPortrait } = useMobileOrientation();
        const isMobilePortrait = isMobile && isPortrait;

        const [status, setStatus] = useState<
            'disconnected' | 'connecting' | 'connected'
        >('disconnected');

        const { audioListeners } = useStoreWithArray(['audioListeners']);

        const {
            addActionProcessor,
            removeActionProcessor,
            setShouldSkipTtsSynthesis,
        } = useInteractionContext(InteractionContextAPI);

        const toggleAudio = useCallback((on: boolean) => {
            if (audioRef.current && videoRef.current) {
                // Audio is played from both of them
                videoRef.current.muted = !on;
                audioRef.current.muted = !on;
            }
        }, []);

        useEffect(() => {
            setShouldSkipTtsSynthesis(false);
            audioListeners.add(toggleAudio);
            return () => {
                audioListeners.delete(toggleAudio);
            };
        }, [audioListeners, toggleAudio, setShouldSkipTtsSynthesis]);

        const startSimliConnection = useCallback(() => {
            // SimliClient clears all listeners on disconnect / failure
            // so we have to add them every time before we call the start
            setStatus('connecting');
            simliClient.on('connected', () => {
                setStatus('connected');
                addActionProcessor('audio', (action, activeStage) => {
                    const { promise, resolve } = promiseWithResolvers();
                    // There is a possibility that we will stop / interrupt the action
                    // while the PCM16 conversion is running
                    // (e.g. clicking on a hint as soon as it become available)
                    let shouldStillSendToSimli = true;
                    convertMp3ToPCM16(action.payload.auditory.url).then(
                        audioData => {
                            resolve();
                            if (shouldStillSendToSimli) {
                                simliClient.sendAudioData(audioData);
                            }
                        },
                    );
                    return {
                        promise,
                        stop: () => {
                            shouldStillSendToSimli = false;
                            simliClient.ClearBuffer();
                        },
                    };
                });
            });
            simliClient.on('disconnected', () => {
                removeActionProcessor('audio');
                setStatus('disconnected');
            });
            simliClient.on('failed', () => setStatus('disconnected'));

            SIMLI_EVENTS.forEach(simliEvent => {
                simliClient.on(simliEvent, () =>
                    eventHandler(simliEvent, simliClient),
                );
            });

            const isAudioOn = useStore.getState().isAudioOn;
            toggleAudio(isAudioOn);

            simliClient.start();
        }, [
            toggleAudio,
            addActionProcessor,
            removeActionProcessor,
            eventHandler,
        ]);

        useImperativeHandle(ref, () => ({
            start: () => {
                startSimliConnection();
            },
            simliClient,
        }));

        useEffect(() => {
            simliClient.Initialize({
                ...simliConfig,
                videoRef: videoRef.current!,
                audioRef: audioRef.current!,
                SimliURL: '',
                enableConsoleLogs: true,
            });

            startSimliConnection();
            return () => {
                simliClient.close();
            };
        }, [startSimliConnection, simliConfig]);

        const videoStyle = {
            height: isMobilePortrait ? 'calc(100% - 50px)' : '100%', // 50px to account for the top navbar
            width: 'auto',
            objectFit: 'cover',
        } as CSSProperties;

        const blurRadius = 10;

        return (
            <Fragment>
                {backgroundSrc && !backgroundSrc.startsWith('#') ? (
                    <Box
                        // The blur effect makes the edges fade
                        // we get around it by increasing the images size
                        w={`calc(100% + ${4 * blurRadius}px)`}
                        h={`calc(100% + ${4 * blurRadius}px)`}
                        margin={`-${2 * blurRadius}px`}
                        filter={`blur(${blurRadius}px)`}
                        backgroundImage={backgroundSrc}
                        backgroundSize='cover'
                        backgroundPosition='bottom'
                    />
                ) : (
                    <Box
                        w='100%'
                        h='100%'
                        backgroundColor={
                            backgroundSrc || DEFAULT_BACKGROUND_COLOR
                        }
                    />
                )}
                <Box
                    h='100%'
                    w={isMobilePortrait ? '100%' : { base: '55%', xl: '65%' }}
                    position='fixed'
                    bottom='0px'
                >
                    {status === 'connecting' && (
                        <Center h='100%'>
                            <LoadingSpinner />
                        </Center>
                    )}
                    <Center
                        h='100%'
                        alignItems='end'
                        display={status !== 'connected' ? 'none' : undefined}
                    >
                        {shouldUseGreenScreenRemoval ? (
                            <GreenScreenVideo
                                ref={videoRef}
                                autoPlay
                                playsInline
                                style={videoStyle}
                            />
                        ) : (
                            /* eslint-disable-next-line jsx-a11y/media-has-caption */
                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                style={videoStyle}
                            />
                        )}
                    </Center>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <audio ref={audioRef} autoPlay></audio>
                </Box>
            </Fragment>
        );
    },
);
AvatarWrapperSimli.displayName = 'AvatarWrapperSimli';

export default AvatarWrapperSimli;
