import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    Center,
    InputGroup,
    IconButton,
    Tooltip,
    Box,
    IconButtonProps,
} from '@chakra-ui/react';
import UserInputField from '../../atoms/UserInputField/UserInputField';
import { Icon } from '../../atoms';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useStoreWithArray } from '../../stores';
import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import KeyControl from '../../atoms/KeyControl/KeyControl';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { MicSetting } from '../../stores/interaction-settings';

const validationSchema = Yup.object().shape({
    message: Yup.string()
        .required('Message cannot be empty')
        .matches(/^(?!\s*$).+/, 'Message cannot be empty'),
});

interface Props {
    onSubmit: (message: string) => void;
}

const MessageInputButton = ({
    message,
    startASR,
    endASR,
}: {
    message: string;
    startASR: () => void;
    endASR: () => void;
}) => {
    const { isMicOn, micMeta } = useStoreWithArray(['isMicOn', 'micMeta']);
    const { awaitingResponse, isAgentBusy } = useInteractionContext(
        InteractionContextStatus,
    );

    const { interrupt } = useInteractionContext(InteractionContextAPI);
    const { isTextMode } = useStoreWithArray(['isTextMode']);
    const commonBtnProps: Omit<IconButtonProps, 'aria-label'> = {
        rounded: 'full',
        boxSize: '45px',
    };
    const commonFontAwesomeIconProps: Omit<FontAwesomeIconProps, 'icon'> = {
        size: 'lg',
    };

    if (isAgentBusy) {
        return (
            <Tooltip placement='bottom' label='Interrupt'>
                <IconButton
                    aria-label='Interrupt'
                    onClick={interrupt}
                    {...commonBtnProps}
                >
                    <Icon
                        iconChoice='stop'
                        fontAwesomeProps={commonFontAwesomeIconProps}
                    />
                </IconButton>
            </Tooltip>
        );
    } else if (isMicOn) {
        return (
            <Tooltip
                placement='bottom'
                label={
                    <Box textAlign='center'>
                        Speak now <br />
                        {micMeta && (
                            <Fragment>
                                {micMeta.trigger === 'spacebar'
                                    ? 'Release spacebar '
                                    : 'Click again '}
                                to send
                            </Fragment>
                        )}
                    </Box>
                }
                isOpen
            >
                <IconButton
                    aria-label='Speak now'
                    onClick={endASR}
                    {...commonBtnProps}
                >
                    <Icon
                        iconChoice='micOn'
                        fontAwesomeProps={{
                            ...commonFontAwesomeIconProps,
                            color: 'green',
                        }}
                    />
                </IconButton>
            </Tooltip>
        );
    } else if (message === '' && !isTextMode) {
        return (
            <Fragment>
                <Tooltip
                    placement='bottom'
                    label={
                        <Box textAlign='left'>
                            Hold spacebar or <br />
                            click the mic to speak
                        </Box>
                    }
                >
                    <IconButton
                        aria-label='Start speech recognition'
                        onClick={startASR}
                        disabled={awaitingResponse}
                        {...commonBtnProps}
                    >
                        <Icon
                            iconChoice='micOn'
                            fontAwesomeProps={commonFontAwesomeIconProps}
                        />
                    </IconButton>
                </Tooltip>
            </Fragment>
        );
    } else {
        return (
            <IconButton
                aria-label='Send message'
                type='submit'
                disabled={message === '' || awaitingResponse}
                {...commonBtnProps}
            >
                <Icon
                    iconChoice='arrowUp'
                    fontAwesomeProps={commonFontAwesomeIconProps}
                />
            </IconButton>
        );
    }
};

const getPlaceholder = (
    isMicOn: boolean,
    awaitingResponse: boolean,
    isTranscribing: boolean,
    isTextMode: boolean,
) => {
    if (isMicOn) return 'Listening . . .';
    if (isTranscribing) return 'Transcribing . . .';
    if (awaitingResponse) return 'Thinking . . .';
    else
        return isTextMode
            ? 'Type your message'
            : 'Hold spacebar to speak or type your message';
};

const MessageInput: React.FC<Props> = ({ onSubmit }) => {
    const initialValues = {
        message: '',
    };

    const formSubmit = (
        values: FormikValues,
        { resetForm }: FormikHelpers<any>,
    ) => {
        if (!awaitingResponse) {
            onSubmit(values.message);
            resetForm();
        }
    };

    const { isMicOn, turnMicOn, turnMicOff, isTextMode } = useStoreWithArray([
        'turnMicOn',
        'turnMicOff',
        'isTextMode',
        'isMicOn',
    ]);
    const { interrupt } = useInteractionContext(InteractionContextAPI);
    const {
        awaitingResponse,
        isTranscribing,
        isDisabled,
    } = useInteractionContext(InteractionContextStatus);

    const startRecognition = useCallback(
        async (meta: MicSetting['micMeta']) => {
            if (!awaitingResponse) {
                // When starting speech recognition while the agent is processing,
                // we must first interrupt the current action and wait for completion.
                // This ensures the agent returns to 'Ready' state, which is required
                // for speech recognition to initialize correctly.
                await interrupt();
                turnMicOn(meta);
            }
        },
        [interrupt, turnMicOn, awaitingResponse],
    );

    const placeholder = getPlaceholder(
        isMicOn,
        awaitingResponse,
        isTranscribing,
        isTextMode,
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={formSubmit}
            validationSchema={validationSchema}
        >
            {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
                <Form
                    style={{
                        width: '100%',
                    }}
                >
                    <InputGroup
                        backgroundColor='dark.700'
                        border='1px solid'
                        borderColor='dark.500'
                        borderRadius='10px'
                        p='12px'
                    >
                        <Center w='100%'>
                            <UserInputField
                                placeholder={placeholder}
                                value={values.message}
                                onChange={
                                    awaitingResponse ? undefined : handleChange
                                }
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                                isDisabled={
                                    isMicOn || awaitingResponse || isDisabled
                                }
                            />
                        </Center>
                        <Center>
                            {/* ASR is not supported in text mode */}
                            {!isTextMode && (
                                <KeyControl
                                    keyName='space'
                                    onPush={() => {
                                        startRecognition({
                                            trigger: 'spacebar',
                                        });
                                    }}
                                    onRelease={turnMicOff}
                                    // Space acts as a click as part of default browser keyboard navigation
                                    preventDefault
                                />
                            )}
                            <MessageInputButton
                                message={values.message}
                                startASR={() =>
                                    startRecognition({ trigger: 'click' })
                                }
                                endASR={turnMicOff}
                            />
                        </Center>
                    </InputGroup>
                </Form>
            )}
        </Formik>
    );
};

export default MessageInput;
