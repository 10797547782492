import React, { Fragment, ReactNode } from 'react';
import { medalStyles } from '@recourseai/components/src/theme/consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { BoxProps } from '@chakra-ui/react';
import { Leaderboard } from './LeaderBoardView';

const STATUS_INFO_BOX_BG_YELLOW = 'orange.200';
const STATUS_INFO_BOX_BG_GREEN = 'green.200';

export const getMedalStyles = (position: number) => {
    return position < 4 ? medalStyles[position] : {};
};

export const getCaretIcon = (
    position: number,
    previous_position: number | null,
) => {
    if (previous_position) {
        if (position < previous_position) {
            return <FontAwesomeIcon icon={faCaretUp} color='green' />;
        } else if (position > previous_position) {
            return <FontAwesomeIcon icon={faCaretDown} color='red' />;
        }
    }
};

export const getStatusInfoBoxText = (
    leaderboard: Leaderboard,
    isUserInTopScores: boolean,
): { text: ReactNode; bg: BoxProps['bg'] } | undefined => {
    if (!leaderboard?.leaderboard_top) {
        return {
            text: (
                <Fragment>
                    <b>The leaderboard remains unclaimed!</b>
                    Keep building your score and take the lead before anyone
                    else does.
                </Fragment>
            ),
            bg: STATUS_INFO_BOX_BG_YELLOW,
        };
    }
    if (!leaderboard.student_leaderboard_entry?.position) {
        return {
            text: (
                <Fragment>
                    <b>You cannot see your ranking yet</b> as you do not have
                    enough points. Try reattempting or completing more
                    activities to build your score and see your ranking
                </Fragment>
            ),
            bg: STATUS_INFO_BOX_BG_YELLOW,
        };
    }
    if (leaderboard.student_leaderboard_entry?.position === 1) {
        return {
            text: (
                <Fragment>
                    <b>Congratulations!</b> You are ranked <b>#1</b> with an
                    impressive {leaderboard.student_leaderboard_entry?.points}{' '}
                    points! Keep up the momentum and defend your top spot.
                </Fragment>
            ),
            bg: STATUS_INFO_BOX_BG_GREEN,
        };
    }
    if (isUserInTopScores) {
        return {
            text: (
                <Fragment>
                    <b>
                        You are now ranked #
                        {leaderboard.student_leaderboard_entry?.position}!
                    </b>{' '}
                    You have secured your position on the leaderboard with{' '}
                    {leaderboard.student_leaderboard_entry?.points} points, just{' '}
                    {+leaderboard.leaderboard_top[0].points -
                        +leaderboard.student_leaderboard_entry?.points}{' '}
                    points away from the top spot.
                </Fragment>
            ),
            bg: STATUS_INFO_BOX_BG_GREEN,
        };
    }
    if (!isUserInTopScores) {
        return {
            text: (
                <Fragment>
                    <b>Keep pushing forward!</b> You are currently ranked{' '}
                    {leaderboard.student_leaderboard_entry?.position}. Try and
                    increase your score to claim your place on the leaderboard.
                </Fragment>
            ),
            bg: STATUS_INFO_BOX_BG_YELLOW,
        };
    }

    return undefined;
};
