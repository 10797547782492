import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useQueue from '../../../../core/src/hooks/useQueue';
import useTypewriterEffect from '../../../../core/src/hooks/useTypewriterEffect';
import {
    ChatConnection,
    ChatConnOptions,
    ChatWebsocketCloseReason,
    ChatWebsocketCodes,
    openChatHttpConn,
    openChatWebsocketConn,
} from './InteractionConnection';
import { getTextElements, parseChatEntries } from './utils';
import { Session } from '../../../../../apps/mooc-frontend/src/components/activities/ActivityContent';
import ExerciseAPI from '../../../../../apps/mooc-frontend/src/components/activities/ExerciseAPI';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import { InteractionActions, useInteractionAgent } from './useInteractionAgent';
import * as Sentry from '@sentry/browser';
import useStableCallback from '../../hooks/useStableCallback';
import { useStoreWithArray } from '../../stores';

interface RunnableAction {
    // Resolves when an action has finished processing
    promise: Promise<any>;
    // Will immediately terminate a running action
    stop: () => void;
}

export type ActionProcessor = (
    action: AgentAction,
    activeStage: ActiveStage,
) => RunnableAction | undefined;

export interface InitProps {
    session: Session;
    exerciseAPI: ExerciseAPI;
    useStreaming: boolean;
    requiredProcessors?: ActionProcessorName[];
    messageDisplayLimit?: number;
}

export type ActionProcessorName = 'audio' | 'text' | string;

const useInteraction = ({
    session,
    exerciseAPI,
    useStreaming,
    requiredProcessors = [],
    messageDisplayLimit,
}: InitProps) => {
    const { isTextMode } = useStoreWithArray(['isTextMode']);

    const sessionId = session.id;
    const stageId = session.active_stage.id;
    const activeStage = session.active_stage;

    const chatUrl = `sessions/${sessionId}/stages/${stageId}/`;

    const [messages, setMessages, addMessages] = useQueue<TextMessage>(
        messageDisplayLimit,
    );

    const hints = useMemo(() => {
        return (
            session.active_stage.interaction_stage.hint
                ?.split('\n')
                .map(hint => hint.trim()) || []
        );
    }, [session.active_stage.interaction_stage.hint]);

    // Do not use spead operator as it will recreate the object unnecessarily causing performance issues downstream
    const {
        act,
        status,
        isDisabled,
        isAgentBusy,
        isConnected,
        isTranscribing,
        awaitingResponse,
    } = useInteractionAgent();

    const { displayTextual, stop: stopTextual } = useTypewriterEffect(
        isTextMode ? 20 : 40,
        setMessages,
    );

    const chatConnectionRef = useRef<ChatConnection | null>(null);
    const runningActions = useRef(new Map<any, RunnableAction[]>());
    const interruptedActions = useRef(new Set<any>());
    const hasAlreadySentStartOrResume = useRef(false);

    const [actionProcessors, setActionProccessors] = useState<
        Map<ActionProcessorName, ActionProcessor>
    >(new Map());

    const hasRequiredProcessors = requiredProcessors.every(p =>
        actionProcessors.has(p),
    );

    const addActionProcessor = useCallback(
        (name: ActionProcessorName, processor: ActionProcessor) => {
            setActionProccessors(oldMap => {
                const newMap = new Map(oldMap);
                if (newMap.has(name)) {
                    console.warn(
                        `[useInteraction]: Overriding action processor ${name}`,
                    );
                }
                newMap.set(name, processor);
                return newMap;
            });
        },
        [],
    );
    const removeActionProcessor = useCallback((name: string) => {
        setActionProccessors(oldMap => {
            const newMap = new Map(oldMap);
            newMap.delete(name);
            return newMap;
        });
    }, []);

    const runAction: (
        action: AgentAction,
        activeStage: ActiveStage,
    ) => RunnableAction = useStableCallback(
        (action, activeStage) => {
            const { promise, resolve } = promiseWithResolvers();

            const agentActionTasks: RunnableAction['promise'][] = [];
            const stopFunctions: RunnableAction['stop'][] = [];

            const stop = () => stopFunctions.forEach(f => f());

            actionProcessors.forEach(processor => {
                const processorOutcome = processor(action, activeStage);
                if (processorOutcome) {
                    const { promise, stop } = processorOutcome;
                    agentActionTasks.push(promise);
                    stopFunctions.push(stop);
                }
            });

            Promise.all(agentActionTasks).then(() => resolve());
            return { promise, stop };
        },
        [actionProcessors],
    );

    useEffect(() => {
        const textProcessor: ActionProcessor | undefined = action => {
            const { textual, chunks } = action.payload;
            if (!textual?.text) return;

            const { promise, resolve } = promiseWithResolvers();

            let displayText: AvatarText = [];
            if (chunks && chunks.length) {
                chunks.forEach(({ text, citations }) => {
                    const {
                        mainText,
                        citationsMarkdown,
                        endOfText,
                    } = getTextElements(text, citations);

                    displayText.push(
                        ...mainText.split(''),
                        ...citationsMarkdown,
                        endOfText,
                    );
                });
            } else {
                displayText = textual.text.split('');
            }

            displayTextual(
                displayText,
                action.id,
                action.partial,
                !!textual.hidden,
                action.payload.media?.attachments,
                () => resolve(),
            );

            const stop = () => stopTextual(action.id);

            return {
                promise,
                stop,
            };
        };
        addActionProcessor('text', textProcessor);

        return () => {
            removeActionProcessor('text');
        };
    }, [
        addActionProcessor,
        displayTextual,
        removeActionProcessor,
        stopTextual,
    ]);

    const completeAction = useCallback(
        (id: any) => {
            const promises = runningActions.current
                .get(id)
                ?.map(runnable => runnable.promise);

            if (promises) {
                Promise.all(promises).finally(() => {
                    act(InteractionActions.processed);
                    runningActions.current.delete(id);
                });
            } else {
                act(InteractionActions.processed);
            }
        },
        [act],
    );

    const completeStage = useCallback(async (): Promise<{
        status?: string;
        error?: string;
    }> => {
        if (!activeStage) {
            throw new Error('No active stage');
        }

        if (chatConnectionRef.current !== null) {
            chatConnectionRef.current?.close(ChatWebsocketCodes.CLOSE_NORMAL);
        }

        try {
            const data = await exerciseAPI.post(chatUrl + 'complete/');
            if (data.stage.status === 'completed') {
                return { status: 'completed' };
            } else if (data.stage.status === 'cancelled') {
                return {
                    error:
                        'This interaction has been cancelled and can no longer be completed',
                };
            } else {
                return {
                    error: `Interaction with status ${data.stage.status} could not be completed`,
                };
            }
        } catch (e) {
            console.log(e);
            Sentry.captureException(e);
            return {
                error:
                    'An error has occurred while completing the attempt, try again later',
            };
        }
    }, [activeStage, chatUrl, exerciseAPI]);

    useEffect(() => {
        act(InteractionActions.initialise);
    }, [act]);

    useEffect(() => {
        const chatMessages = parseChatEntries(activeStage.entries);
        setMessages(chatMessages);
    }, [activeStage.entries, setMessages]);

    useEffect(() => {
        let shouldStillUseConnection = true;

        if (hasRequiredProcessors) {
            const connOptions: ChatConnOptions = {
                chatUrl,
                activeStage: activeStage,
                act,
                sessionData: session,
                exerciseAPI: exerciseAPI,
                skipAction: action => {
                    const { payload } = action;
                    return (
                        interruptedActions.current.has(action.id) ||
                        !!payload.control?.finished_actions.every(id =>
                            interruptedActions.current.has(id),
                        )
                    );
                },
                setActions: actions => {
                    actions.forEach(action => {
                        const { payload } = action;
                        const isFinal = !action.partial;
                        const hasFinishedActions = !!payload.control
                            ?.finished_actions.length;
                        const isProcessingRequired = payload.textual?.text;

                        // The below handles an edge case to this, if we don't receive any
                        // speak action, and want to early exit the processing state, usually
                        // at the start of interactions, the below conditions will take care of it
                        if (
                            !isProcessingRequired &&
                            !hasFinishedActions &&
                            isFinal
                        ) {
                            act(InteractionActions.processed);
                            return;
                        }

                        if (payload.control?.finished_actions.length) {
                            payload.control.finished_actions
                                .filter(
                                    id => !interruptedActions.current.has(id),
                                )
                                .forEach(completeAction);
                            return;
                        }

                        if (action.id) {
                            act(InteractionActions.process);
                            if (!runningActions.current.has(action.id)) {
                                runningActions.current.set(action.id, []);
                            }

                            const actionParts = runningActions.current.get(
                                action.id,
                            )!;
                            const runningAction = runAction(
                                action,
                                activeStage,
                            );
                            actionParts.push(runningAction);

                            if (isFinal) {
                                completeAction(action.id);
                            }
                        }
                    });
                },
                onSuccess: connection => {
                    if (shouldStillUseConnection) {
                        chatConnectionRef.current = connection;
                        if (!hasAlreadySentStartOrResume.current) {
                            hasAlreadySentStartOrResume.current = true;
                            sendAction({
                                action_type:
                                    activeStage!.entries?.length > 0
                                        ? 'resume'
                                        : 'start',
                                payload: {},
                                skip_tts_synthesis:
                                    shouldSkipTtsSynthesis.current,
                            });
                        }
                    } else {
                        connection.close(
                            ChatWebsocketCodes.CLOSE_NORMAL,
                            // escape hatch to prevent the state from updating to 'disconnected'
                            // because the new connection will have been initialised by that point
                            ChatWebsocketCloseReason.CLIENT_RECONNECT,
                        );
                        act(InteractionActions.disconnect);
                    }
                },
                // Skips unnecessary reconnects
                shouldReconnect: () => shouldStillUseConnection,
                onError: console.log,
            };

            const openConn = useStreaming
                ? openChatWebsocketConn
                : openChatHttpConn;

            openConn(connOptions);
        }

        return () => {
            shouldStillUseConnection = false;
            chatConnectionRef.current?.close(
                ChatWebsocketCodes.CLOSE_NORMAL,
                ChatWebsocketCloseReason.CLIENT_RECONNECT,
            );
            act(InteractionActions.disconnect);
        };
        // Missing deps: exerciseAPI, activeStage, session
        // they can change, but meaningful changes (when the effect needs to re-run) will be reflected
        // in a chatUrl change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        runAction,
        chatUrl,
        useStreaming,
        act,
        completeAction,
        hasRequiredProcessors,
    ]);

    const interrupt = useCallback(async () => {
        const promises: Promise<any>[] = [];
        Array.from(runningActions.current.entries()).forEach(
            ([id, runningActions]) => {
                completeAction(id);
                interruptedActions.current.add(id);
                runningActions.forEach(ra => ra.stop());
                promises.push(...runningActions.map(ra => ra.promise));
            },
        );
        await Promise.all(promises);
    }, [completeAction]);

    const shouldSkipTtsSynthesis = useRef(true);
    const setShouldSkipTtsSynthesis = useCallback(
        b => (shouldSkipTtsSynthesis.current = b),
        [],
    );

    const sendAction = useCallback(
        (actionData: { action_type: string; [key: string]: any }) => {
            act(InteractionActions.send);
            chatConnectionRef.current?.send(JSON.stringify(actionData));
        },
        [act],
    );

    const sendMessage = useCallback(
        async (message: string, meta: UserMessageMeta) => {
            await interrupt();

            addMessages({
                type: 'user',
                text: message,
                actionId: new Date().getTime(),
            });

            sendAction({
                action_type: 'utterance',
                payload: {
                    text: message,
                    meta,
                },
                skip_tts_synthesis: shouldSkipTtsSynthesis.current,
            });
        },
        [addMessages, interrupt, sendAction],
    );

    return {
        act,
        hints,
        messages,
        activeStage,
        interrupt,
        sendMessage,
        agentState: status,
        isAgentBusy,
        isConnected,
        isDisabled,
        isTranscribing,
        setShouldSkipTtsSynthesis,
        awaitingResponse,
        addActionProcessor,
        removeActionProcessor,
        completeStage,
    };
};
export default useInteraction;
